<header class="main-header">
	<div *ngIf="isMenuOppened"
		style="position: absolute; width: 100%; height: 1000px; background-color: #202022; padding: 24px;">
		<div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
			<img src="../../../assets/img/LogoInvestWhite.png" />

			<div (click)="toggleMenu()">
				<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M3.33334 3.33334L16.6667 16.6667" stroke="white" stroke-width="1.66667" stroke-linecap="round"
						stroke-linejoin="round" />
					<path d="M3.33334 16.6667L16.6667 3.33334" stroke="white" stroke-width="1.66667" stroke-linecap="round"
						stroke-linejoin="round" />
				</svg>
			</div>
		</div>

		<p style="font-family: Poppins, sans-serif; color: #909090;
		      font-size: 14px;
		      font-weight: 500;
		      line-height: 14px;
		      text-align: left;
					margin-top: 40px;
		      ">Menu</p>
		<ul class="left-side-menu">
			<li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
				<a (click)="toggleMenu()" routerLink="./rounds/assets/list">
					<i>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
							<path d="M5.33333 2H2.66667C2.29848 2 2 2.29848 2 2.66667V5.33333" stroke="white" stroke-width="1.5"
								stroke-linecap="round" stroke-linejoin="round" />
							<path d="M5.33333 14.0003H2.66667C2.29848 14.0003 2 13.7019 2 13.3337V10.667" stroke="white"
								stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path d="M10.6666 14.0003H13.3333C13.7015 14.0003 14 13.7019 14 13.3337V10.667" stroke="white"
								stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path d="M10.6666 2H13.3333C13.7015 2 14 2.29848 14 2.66667V5.33333" stroke="white" stroke-width="1.5"
								stroke-linecap="round" stroke-linejoin="round" />
							<path d="M9.66671 6H6.33337V10H9.66671V6Z" stroke="white" stroke-width="1.5" stroke-linejoin="round" />
							<path d="M8 6V4" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path d="M8 12V10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</i>
					<span class="menu-text">Ofertas públicas</span>
				</a>
			</li>
			<li routerLinkActive="active">
				<a (click)="toggleMenu()" routerLink="./portfolio">
					<i>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
							<g clip-path="url(#clip0_460_1376)">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M5.99402 3.98931L10.5949 1.33301L12.1324 3.99597L5.99402 3.98931Z" stroke="#BFBFBF"
									stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M1.33337 4.66667C1.33337 4.29847 1.63185 4 2.00004 4H14C14.3682 4 14.6667 4.29847 14.6667 4.66667V14C14.6667 14.3682 14.3682 14.6667 14 14.6667H2.00004C1.63185 14.6667 1.33337 14.3682 1.33337 14V4.66667Z"
									stroke="#BFBFBF" stroke-width="1.5" stroke-linejoin="round" />
								<path
									d="M11.75 11.0003H14.6667V7.66699H11.75C10.7835 7.66699 10 8.41319 10 9.33366C10 10.2541 10.7835 11.0003 11.75 11.0003Z"
									stroke="#BFBFBF" stroke-width="1.5" stroke-linejoin="round" />
								<path d="M14.6666 5.5V13.5" stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round" />
							</g>
							<defs>
								<clipPath id="clip0_460_1376">
									<rect width="16" height="16" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</i>
					<span class="menu-text">Análise de carteira</span>
				</a>
			</li>
			<li routerLinkActive="active">
				<a (click)="toggleMenu()" routerLink="./statement">
					<i>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
							<path
								d="M12.3333 2.66699H3.66667C3.29848 2.66699 3 2.96547 3 3.33366V14.0003C3 14.3685 3.29848 14.667 3.66667 14.667H12.3333C12.7015 14.667 13 14.3685 13 14.0003V3.33366C13 2.96547 12.7015 2.66699 12.3333 2.66699Z"
								stroke="#BFBFBF" stroke-width="1.5" stroke-linejoin="round" />
							<path d="M6 1.33301V3.33301" stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M10 1.33301V3.33301" stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M5.33337 6.33301H10.6667" stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M5.33337 9H9.33337" stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M5.33337 11.667H8.00004" stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</i>
					<span class="menu-text">Extrato financeiro</span>
				</a>
			</li>
			<li (click)="toggleMenu()" *ngIf="scopes.ROLE_ADMIN" class="treeview">
				<a routerLink="./rounds/approval">
					<i>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M5.66663 4.66699L14.6666 8.00033V14.667H5.66663V4.66699Z"
								stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path d="M5.66671 4.66699L1.33337 8.00033V14.667H5.66671" stroke="#BFBFBF" stroke-width="1.5"
								stroke-linecap="round" stroke-linejoin="round" />
							<path d="M11.6666 14.667V10.667L8.66663 9.66699V14.667" stroke="#BFBFBF" stroke-width="1.5"
								stroke-linecap="round" stroke-linejoin="round" />
							<path d="M14.6666 14.667H5.66663" stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</i>
					<span class="header-menu">Empresas</span>
				</a>
			</li>

			<div *ngIf="isSvgRotated">
				<div *ngFor="let item of enterpriseSubItens">
					<div style="display: flex; align-items: center;">
						<p (click)="!item.subCategory ? toggleMenu() : null"
							style="margin-left: 5%; cursor: pointer; margin-bottom: 6%;" class="menu-text"
							(click)="toggleSubItems(item)" [routerLink]="item.link">{{ item.name }}</p>
						<svg *ngIf="item.subCategory" (click)="toggleSubItems(item)"
							style="transform: scaleX(-1); cursor: pointer; margin-left: 30px; margin-bottom: 20px" width="6"
							height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1.45834 1.5L4.95834 5L1.45834 8.5" stroke="white" stroke-width="1.3" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</div>

					<ul *ngIf="item.subCategory && item.isExpanded">
						<li *ngFor="let subItem of item.subCategory" style="margin-bottom: 6%; margin-top: -3%;">
							<a (click)="toggleMenu()" [routerLink]="subItem.link">{{ subItem.name }}</a>
						</li>
					</ul>
				</div>
			</div>

			<li *ngIf="scopes.ROLE_ADMIN" class="treeview">
				<a routerLink="./rounds/approval/company/publish">
					<i>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g clip-path="url(#clip0_1143_1711)">
								<path
									d="M4.5 13.123C5.4636 13.8808 6.67903 14.3328 8 14.3328C9.32097 14.3328 10.5364 13.8808 11.5 13.123"
									stroke="#BFBFBF" stroke-width="1.5" />
								<path
									d="M6.33337 3.24902C4.01714 3.96073 2.33337 6.11703 2.33337 8.66663C2.33337 9.30823 2.44 9.92493 2.63651 10.5"
									stroke="#BFBFBF" stroke-width="1.5" />
								<path
									d="M9.66663 3.24902C11.9829 3.96073 13.6666 6.11703 13.6666 8.66663C13.6666 9.30823 13.56 9.92493 13.3635 10.5"
									stroke="#BFBFBF" stroke-width="1.5" />
								<path
									d="M14.3333 11.9997C14.3333 12.4469 14.1572 12.8529 13.8706 13.1522C13.5672 13.4691 13.14 13.6663 12.6667 13.6663C11.7462 13.6663 11 12.9201 11 11.9997C11 11.3296 11.3954 10.752 11.9656 10.4872C12.1787 10.3882 12.4162 10.333 12.6667 10.333C13.5871 10.333 14.3333 11.0792 14.3333 11.9997Z"
									stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M4.99996 11.9997C4.99996 12.4469 4.82383 12.8529 4.53719 13.1522C4.23379 13.4691 3.80659 13.6663 3.33329 13.6663C2.41282 13.6663 1.66663 12.9201 1.66663 11.9997C1.66663 11.3296 2.06201 10.752 2.63218 10.4872C2.84531 10.3882 3.08285 10.333 3.33329 10.333C4.25376 10.333 4.99996 11.0792 4.99996 11.9997Z"
									stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								<path
									d="M9.66671 2.99967C9.66671 3.44687 9.49057 3.85291 9.20394 4.15224C8.90054 4.46907 8.47334 4.66634 8.00004 4.66634C7.07957 4.66634 6.33337 3.92014 6.33337 2.99967C6.33337 2.32964 6.72874 1.75196 7.29894 1.48721C7.51204 1.38825 7.74961 1.33301 8.00004 1.33301C8.92051 1.33301 9.66671 2.0792 9.66671 2.99967Z"
									stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</g>
							<defs>
								<clipPath id="clip0_1143_1711">
									<rect width="16" height="16" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</i>
					<span class="header-menu">Rodadas</span>
				</a>
			</li>
		</ul>

		<hr class="sidebar-divider" style="color: #353535; background-color: #353535;">
		<p style="font-family: Poppins, sans-serif; color: #909090;
						font-size: 14px;
						font-weight: 500;
						line-height: 14px;
						text-align: left;
						margin-top: 10%;
						">Outros</p>
		<ul class="left-side-menu">
			<li routerLinkActive="active">
				<a (click)="toggleMenu()" href="/admin/user/perfil">
					<i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
							<g clip-path="url(#clip0_460_1342)">
								<path
									d="M8.00004 1.33301L6.00004 3.33301H3.33337V5.99967L1.33337 7.99967L3.33337 9.99967V12.6663H6.00004L8.00004 14.6663L10 12.6663H12.6667V9.99967L14.6667 7.99967L12.6667 5.99967V3.33301H10L8.00004 1.33301Z"
									stroke="#BFBFBF" stroke-width="1.4" stroke-linejoin="round" />
								<path
									d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
									stroke="#BFBFBF" stroke-width="1.4" stroke-linejoin="round" />
							</g>
							<defs>
								<clipPath id="clip0_460_1342">
									<rect width="16" height="16" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</i>
					<span class="menu-text">Configurações</span>
				</a>
			</li>
			<li routerLinkActive="active">
				<a (click)="logout()">
					<i>
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
							<path d="M1.93298 8H13.933" stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M5.93297 12L1.93298 8L5.93297 4" stroke="#BFBFBF" stroke-width="1.5" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</i>
					<span class="menu-text">Sair da conta</span>
				</a>
			</li>
		</ul>
	</div>


	<nav class="navbar navbar-fixed-top" style="border-right: 1px solid #E5E5E5;
	  border-bottom: 1px solid #E5E5E5;
	  border-left: 1px solid #E5E5E5;
	  border-top: none !important;
	  background: #fff;
	  width: 85%;
	  height: 8%;
	  margin-left: 17.35%;
	">
		<div style="position: absolute; background-color: #202022; width: 21%; height: 10px; margin-left: -21%;">

		</div>
		<a style="display: inline-flex;
		align-items: center;
		gap: 12px;
		margin-top:1.5%; 
		margin-left: 2%">
			<a href="admin/rounds/assets/list">
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
					<path d="M3.75 7.5V17.5H16.25V7.5L10 2.5L3.75 7.5Z" stroke="#909090" stroke-width="1.66667"
						stroke-linecap="round" stroke-linejoin="round" />
					<path d="M7.91675 12.083V17.4997H12.0834V12.083H7.91675Z" stroke="#909090" stroke-width="1.66667"
						stroke-linejoin="round" />
					<path d="M3.75 17.5H16.25" stroke="#909090" stroke-width="1.66667" stroke-linecap="round" />
				</svg>
			</a>
			<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
				<path d="M4.95837 3.5L8.45837 7L4.95837 10.5" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
					stroke-linejoin="round" />
			</svg>
			<span style="color: #909090;
		  font-family: Poppins, sans-serif;
		  font-size: 12px;
		  font-style: normal;
		  font-weight: 500;
		  line-height: 100%; ">{{ titleHeader.title }}
			</span>
		</a>
		<div class="navbar-custom-menu" style="display: inline-flex;
		align-items: center;
		gap: 8px;">
			<ul class="nav navbar-nav">
				<li class="dropdown notifications-menu">
					<a style="font-size: 16px;" href="#" class="dropdown-toggle" data-toggle="dropdown">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="12" cy="12" r="12" fill="black" fill-opacity="0.04" />
							<path
								d="M8.5 9.66699C8.5 8.73873 8.86875 7.8485 9.52513 7.19212C10.1815 6.53574 11.0717 6.16699 12 6.16699C12.9283 6.16699 13.8185 6.53574 14.4749 7.19212C15.1313 7.8485 15.5 8.73873 15.5 9.66699C15.5 13.7503 17.25 14.917 17.25 14.917H6.75C6.75 14.917 8.5 13.7503 8.5 9.66699Z"
								stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							<path
								d="M11.0083 17.25C11.106 17.4276 11.2495 17.5757 11.424 17.6789C11.5984 17.782 11.7973 17.8364 12 17.8364C12.2027 17.8364 12.4016 17.782 12.576 17.6789C12.7505 17.5757 12.894 17.4276 12.9917 17.25"
								stroke="#909090" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</a>
					<ul class="dropdown-menu">
						<li class="header">
							<p>
								<img class="notification" width="20" src="/assets/img/notification.png" />Nenhuma notificação.
							</p>
						</li>
					</ul>
				</li>

				<li *ngIf="scopes.ROLE_ADMIN" class="dropdown notifications-menu">
					<a style="font-size: 17px" href="https://investplus.vc/termos-gerais/" class="dropdown-toggle"
						target="_blank">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="12" cy="12" r="12" fill="black" fill-opacity="0.04" />
							<g clip-path="url(#clip0_233_1469)">
								<path
									d="M12 6.16699L10.25 7.91699H7.91667V10.2503L6.16667 12.0003L7.91667 13.7503V16.0837H10.25L12 17.8337L13.75 16.0837H16.0833V13.7503L17.8333 12.0003L16.0833 10.2503V7.91699H13.75L12 6.16699Z"
									stroke="#909090" stroke-width="1.4" stroke-linejoin="round" />
								<path
									d="M12 13.75C12.9665 13.75 13.75 12.9665 13.75 12C13.75 11.0335 12.9665 10.25 12 10.25C11.0335 10.25 10.25 11.0335 10.25 12C10.25 12.9665 11.0335 13.75 12 13.75Z"
									stroke="#909090" stroke-width="1.4" stroke-linejoin="round" />
							</g>
							<defs>
								<clipPath id="clip0_233_1469">
									<rect width="14" height="14" fill="white" transform="translate(5 5)" />
								</clipPath>
							</defs>
						</svg>
					</a>
				</li>

				<li class="dropdown user user-menu">
					<a href="#" class="dropdown-toggle" data-toggle="dropdown" style="display: flex; align-items: center;">
						<div class="widget-user-image" style="margin-right: 4px;">
							<img class="profile-user-img img-responsive img-circle" src="{{ base64textString }}"
								title="Selecione uma imagem com altura e largura iguais." id="img"
								style="width: 45px; height: 45px; margin-top: -10px;" />
						</div>
						<div style="display: flex; flex-direction: column;">
							<span class="name-user"
								style="color: #000000; font-family: Poppins, sans-serif; font-size: 12px; font-weight: 500; line-height: 12px; text-align: left; margin-top: -5px; margin-right: 5px; margin-bottom: 5px;">
								{{ investor?.fullName.split(" ")[0].toUpperCase() }}
							</span>
							<span style="color: #505050;
				font-family: Poppins;
				font-size: 1.35rem;
				font-style: normal;
				font-weight: 400;
				line-height: 100%;">
								Investidor
							</span>
						</div>
						<i style="color: #1F1F1F; margin-left: 5px; font-weight: 500; font-size: 18px; margin-top: -15px; margin-right: 30px;"
							class="fa fa-fw fa-angle-down"></i>
					</a>
					<ul class="dropdown-menu">
						<li class="user-header" style="background-color: #fff">
							<div class="row">
								<div class="col-md-12">
									<p class="name">
										{{ investor?.fullName }} <br /><span class="email">{{ investor?.email }}</span>
									</p>
								</div>
							</div>
						</li>
						<li class="user-footer">
							<a routerLink="./user" class="menu-link">Perfil</a>
						</li>
						<li class="user-footer">
							<a routerLink="./user/trocar-senha" class="menu-link">Trocar Senha</a>
						</li>
						<li class="user-footer">
							<a routerLink="./portfolio" class="menu-link">Meus Ativos</a>
						</li>
						<li class="user-footer">
							<a routerLink="./rounds/token" class="menu-link">Rodadas Exclusivas</a>
						</li>
						<!-- <li class="user-end">
				<a (click)="logout()" class="logoff">Sair <i class="fa fa-fw fa-long-arrow-right"></i></a>
			  </li> -->
					</ul>
				</li>

			</ul>
		</div>
	</nav>
	<nav class="navbar-mobile" style="
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #E5E5E5;
				padding: 0 20px;
				background: #FFFFFF;
				width: 100%;
				height: 64px;
			">
		<img src="../../../assets/img/mobileLogoInv.png" />

		<div style="display: flex; gap: 10px; align-items: center;">
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M3.75008 14.25V6.75C3.75008 3.8505 6.10058 1.5 9.00008 1.5C11.8996 1.5 14.2501 3.8505 14.2501 6.75V14.25M1.50008 14.25H16.5001"
					stroke="#0C0C0C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
				<path
					d="M9.00008 16.5C10.0356 16.5 10.8751 15.6605 10.8751 14.625V14.25H7.12508V14.625C7.12508 15.6605 7.96455 16.5 9.00008 16.5Z"
					stroke="#0C0C0C" stroke-linecap="round" stroke-linejoin="round" />
			</svg>

			<div (click)="toggleMenu()">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M3.97485 5.97485H19.9748" stroke="#0C0C0C" stroke-width="2" stroke-linecap="round"
						stroke-linejoin="round" />
					<path d="M3.97485 11.9749H19.9748" stroke="#0C0C0C" stroke-width="2" stroke-linecap="round"
						stroke-linejoin="round" />
					<path d="M3.97485 17.9749H19.9748" stroke="#0C0C0C" stroke-width="2" stroke-linecap="round"
						stroke-linejoin="round" />
				</svg>
			</div>
		</div>
	</nav>
</header>