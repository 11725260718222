<div class="login-box" style="border: 1px solid #E5E5E5">
	<div *ngIf="!mfaStep">
	  <div class="login-box-body">
		<div *ngIf="login">
		  <div class="login-logo text-center" style="text-align: center;">
			<a href="https://investplus.vc">
			  <img src="/assets/img/fcjinvestplus.png" alt="" style="width: 45%; margin-top: -20%;"/>
			</a>
		  </div>
		  
		  <p style="font-weight: 400; font-size: 16px; color: #5F5F5F; margin-top: -9%; margin-bottom: 9%;">
			Entre para iniciar sua sessão!
		  </p>
  
		  <form class="form-signin" method="POST" action="#" role="form" style="margin-left: 5%; gap: 1;">
			<div class="form-group">
			  <label for="signupEmail">E-mail</label>
			  <div class="input-group">
				<input
				  (blur)="validateEmail()"
				  id="signupEmail"
				  required
				  type="email"
				  maxlength="100"
				  class="form-control"
				  [(ngModel)]="email"
				  [ngModelOptions]="{ standalone: true }"
				  placeholder="Digite seu e-mail"
				  style="background-color: #F5F5FA;"
				/>
			  </div>
			  <span *ngIf="emailValidator" class="help-block">
				<i class="fa fa-remove"></i> {{ emailError }}
			  </span>
			</div>
  
			<div class="form-group">
			  <label for="signupPassword">Senha</label>
			  <div class="input-group">
				<input
				  id="signupPassword"
				  [type]="passwordFieldType"
				  required
				  maxlength="25"
				  class="form-control"
				  [(ngModel)]="password"
				  [ngModelOptions]="{ standalone: true }"
				  placeholder="Digite sua senha"
				  style="background-color: #F5F5FA; padding-right: 40px;"
				/>
				<i (click)="togglePasswordVisibility()" [class]="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'" class="eye-icon"></i>
			  </div>
			  <div class="forgot-password">
				<span>Esqueceu sua senha? 
				  <a [routerLink]="['/auth/forgot']" style="color: #003A7D;">Clique aqui</a>
				</span>
			  </div>
			</div>
  
			<div class="row text-center">
			  <div class="col-xs-11 text-center">
				<button
				  (click)="onLogin()"
				  id="signupSubmit"
				  type="submit"
				  class="col-md-12 btn btn-primary btn-flat mb-3"
				  [disabled]="loading"
				>
				  Entrar
				</button>
			  </div>
			</div>
			<div style="margin-top: 1em;">
				Ainda não tem uma conta? 
				<a [routerLink]="['/auth/register']" style="color: #003A7D;">Cadastre-se agora!</a>
			</div>
		  </form>
		</div>

		<div *ngIf="!login">
		  <form class="form-signin" method="POST" action="#" role="form">
			<p class="login-box-msg text-center">
			  Digite por favor sua senha definitiva para entrar na plataforma.
			</p>

			<div class="form-group">
			  <label for="signupPassword">Digite sua nova senha *</label>
			  <div class="input-group">
				<input
				  id="signupPassword"
				  [type]="passwordFieldType"
				  maxlength="25"
				  class="form-control"
				  placeholder="Digite pelo menos 8 caracteres"
				  [(ngModel)]="registrationUser.password"
				  (input)="onPasswordInput()"
				  [ngModelOptions]="{ standalone: true }"
				  style="background-color: #F5F5FA; padding-right: 40px;"
				/>
				<i (click)="togglePasswordVisibility()" [class]="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'" class="eye-icon"></i>
			  </div>
			</div>

			<div *ngIf="registrationUser.password" class="password-strength">
			  <label>Força da senha: {{ senhaStrength }}</label>
			  <div class="progress">
				<div class="progress-bar" 
					 [ngClass]="{
						'bg-danger': senhaScore <= 2, 
						'bg-warning': senhaScore === 3, 
						'bg-success': senhaScore === 4, 
						'bg-very-strong': senhaScore === 5}"
					 [style.width]="(senhaScore / 5) * 100 + '%'">
				</div>
			  </div>
			  <ul *ngIf="senhaSuggestions.length > 0">
				<li *ngFor="let suggestion of senhaSuggestions">{{ suggestion }}</li>
			  </ul>
			</div>

			<div class="form-group text-center">
			  <button
				(click)="onRegister()"
				id="signupSubmit"
				type="submit"
				class="btn btn-primary"
			  >
				Confirmar
			  </button>
			</div>

			<p class="login-box-password">
			  * A senha deve conter no mínimo 6 caracteres, incluindo uma letra maiúscula e um numeral.
			</p>
		  </form>
		</div>
	  </div>
	</div>
</div>
