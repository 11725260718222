<app-loader [loader]="loader"></app-loader>
<section class="content-header" style="margin-top: 2%;">
  <div class="row" *ngIf="!loader">
    <div class="col-xs-12 col-md-12">
      <button class="btn-upangel pull-right" (click)="exportAsXLSX()">Exportar <i class="fa fa-fw fa-download"></i></button>
    </div>
  </div>
</section>
<section class="content">
  <div *ngIf="!loader">
    <div class="row">
      <div class="col-xs-12">
        <div class="box">
          <div class="box-body table-responsive no-padding">
            <table class="table table-striped">
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>CPF/CNPJ</th>
                <th>RG</th>
                <th>Possui Investimento</th>
                <th>Cadastro</th>
              </tr>
              <tr *ngFor="let investor of investors">
                <td>{{ investor.fullName }}</td>
                <td>{{ investor.email }}</td>
                <td>{{ investor.phone | phone }}</td>
                <td>{{ investor.cpf | cpf }} {{ investor.cnpj | cnpj }}</td>
                <td>{{ investor.rg }}</td>
                <td>{{ investor.totalInvestedOthers > 0 ? 'S' : 'N' }}</td>
                <td>{{ investor.created | date:'dd/MM/yyyy' }}</td>
              </tr>
            </table>
            <app-count [count]="!(investors?.length > 0)" [textContador]="textRegister"></app-count>

            <div style="width: 100%; display: flex; justify-content: center; margin-top: 50px; align-items: center; gap: 32px;">
                <p 
                (click)="currentPage === 1 ? null : changePage(currentPage - 1)" 
                [ngStyle]="{
                  'cursor': currentPage === 1 ? 'not-allowed' : 'pointer',
                  'color': currentPage === 1 ? '#909090' : '#035A7A'
                }"
                style="font-family: Poppins, sans-serif; font-weight: 400; margin-bottom: 0;">
                Anterior
              </p>            
              <div style="display: flex; align-items: center; gap: 15px;">
                <ng-container *ngIf="totalPages > 1">
                  <div *ngFor="let page of getPaginationRange(totalPages); let i = index" 
                    (click)="page !== '...' && changePage(page)"
                    style="width: 28px; height: 28px; display: flex; align-items: center; justify-content: center; border-radius: 4px; cursor: pointer; margin: 0 2px;">
                    <p [style.background-color]="page === currentPage ? '#035A7A' : 'transparent'"
                      [ngStyle]="{'color': page === currentPage ? '#FFFF' : 'inherit'}"
                      style="font-family: Poppins, sans-serif; font-weight: 400; margin-bottom: 0; width: 50px; padding: 5px; border-radius: 5px; text-align: center;">
                      {{ page }}
                    </p>
                  </div>
                </ng-container>
              </div>
              <p 
                (click)="currentPage === totalPages ? null : changePage(currentPage + 1)"
                [ngStyle]="{'cursor': currentPage === totalPages ? 'not-allowed' : 'pointer'}"
                style="font-family: Poppins, sans-serif; font-weight: 500; color: #035A7A; margin-bottom: 0;">
                Próximo
              </p>
              <select [(ngModel)]="itemsPerPage" (change)="onItemsPerPageChange()" style="margin-left: 20px;">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>

            <div class="pagination-footer">
              <p>Total de investidores: {{ actualTotalInvestors }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>