<div class="banner">
  <div class="login-box" style="border: 1px solid #E5E5E5">
    <div class="login-box-body">
      <div class="login-logo text-center">
        <a routerLink="/">
          <img
            src="/assets/img/fcjinvestplus.png"
            alt=""
            class="img-responsive"
            style="width:45%; margin-top: -6%; margin-bottom: 8%;"
          />
        </a>
      </div>
      <div *ngIf="loader" class="loader-overlay">
        <div class="spinner"></div>
      </div>
      <div *ngIf="!loader">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <app-form
            class="required"
            [label]="'Nome Completo'"
            [errorMessage]="'O campo nome é obrigatório'"
          >
            <input
              type="text"
              style="font-family: Poppins, sans-serif; font-weight: 500; font-size: 14px; line-height: 16px; color: #27272A; margin-bottom: 0.7em;"
              formControlName="fullName"
              class="form-control"
              style="background-color: #F5F5FA; border: none; padding: 20px;"
              placeholder="Digite aqui o seu nome"
            />
          </app-form>

          <app-form
            class="required"
            [label]="'Usuário'"
            [errorMessage]="'O campo usuário é obrigatório'"
          >
            <input
              style="font-family: Poppins, sans-serif; font-weight: 500; font-size: 14px; line-height: 16px; color: #27272A; margin-bottom: 0.7em;"
              type="text"
              formControlName="nickname"
              class="form-control"
              style="background-color: #F5F5FA; border: none; padding: 20px;"
              placeholder="Digite aqui o seu nome de usuário"
            />
          </app-form>

          <app-form
            class="required"
            [label]="'E-mail'"
            [errorMessage]="'O campo e-mail é obrigatório'"
          >
            <input type="email" formControlName="email" class="form-control" style="background-color: #F5F5FA; border: none; padding: 20px;" placeholder="Digite aqui o seu e-mail" />
          </app-form>

          <app-form
            class="required"
            [errorMessage]="'Esta campo é obrigatório'"
          >
            <label style="font-family: Poppins, sans-serif; font-weight: 600; font-size: 13px; line-height: 16px; color: #27272A; margin-bottom: 0.7em;">Quero investir como</label>
            <div class="invest-buttons">
              <button style="font-weight: 600;" type="button" (click)="selectType('cpf')" [class.selected]="form.value.typeDocument === 'cpf'">Pessoa Física</button>
              <button style="font-weight: 600;" type="button" (click)="selectType('cnpj')" [class.selected]="form.value.typeDocument === 'cnpj'">Pessoa Jurídica</button>
            </div>
          </app-form>

          <div *ngIf="form.value.typeDocument">
            <app-form
              class="required"
              [label]="form.value.typeDocument.toUpperCase()"
              [errorMessage]="cpfValidator"
            >
              <input
                type="text"
                (blur)="validate()"
                [placeholder]="'Insira aqui o seu ' + form.value.typeDocument.toUpperCase()"
                formControlName="document"
                class="form-control {{ documentClass }}"
                style="background-color: #F5F5FA; border: none; padding: 20px;"
              />
            </app-form>
          </div>

          <div class="row text-box-footer">
            <div class="col-xs-12 text-center">
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 text-center">
              <button type="submit" [disabled]="loader" class="btn">
                Cadastrar
              </button>
            </div>
          </div>
          <div class="form-group" style="text-align: center; margin-top: 3%;">
              <label>
                  <input
                      type="checkbox"
                      formControlName="termosGerais"
                      class="styled-checkbox"
                      id="termosGerais"
                  />
                  <label for="termosGerais" class="checkbox-label">Li e concordo com os <general-terms></general-terms></label>
              </label>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>