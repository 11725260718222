<a (click)="showDialog()" href="javascript:void(0)" style="text-decoration: underline; font-weight: 400; color: #003A7D;">Termos gerais</a>
<modal id="termos-gerais" class="hhidden">
  <div class="termos-gerais-text">
    <p></p>
    <h2>Termos Gerais</h2>
    <p></p>
    <p></p>
    <p>
      Seja bem-vindo a InvestPlus! Agradecemos seu interesse em utilizar nossa
      plataforma.
    </p>
    <p></p>
    <p></p>
    <p>
      O aceite aos Termos Gerais (“ACORDO”) estabelece&nbsp; a relação e define
      os direitos e obrigações entre: InvestPlus, sociedade empresária limitada, inscrita no CNPJ/MF sob 38.268.345/0001-01, com sede na
      Paraíba, 330, andar 17 sala 21, no bairro Funcionários da cidade de Belo Horizonte do estado de Minas Gerais no CEP 30130-917<a href="https://investplus.vc"> investplus.vc</a>&nbsp; ,
      (“Plataforma InvestPlus ”, “InvestPlus ” ou “Plataforma”) e; O USUÁRIO
      INVESTIDOR da Plataforma InvestPlus ao concordar com os termos de
      celebração deste ACORDO (“USUÁRIO INVESTIDOR”).
    </p>
    <p></p>
    <p></p>
    <p>1. <strong>OBJETO DO ACORDO</strong></p>
    <p></p>
    <p></p>
    <p>
      <strong>1.1</strong> O presente instrumento tem como objeto regular o
      relacionamento entre a InvestPlus e os Usuários da Plataforma. Ao
      utilizarem a Plataforma, os USUÁRIOS estarão automaticamente e
      expressamente vinculados aos termos aqui previstos.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>1.2 </strong>A InvestPlus é um prestador de serviços de tecnologia
      e proprietário e administrador da Plataforma.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>1.3 </strong>O ADMINISTRADOR não irá realizar cobrança para se
      tornar USUÁRIO INVESTIDOR da Plataforma InvestPlus nem para atualizar como
      USUÁRIO INVESTIDOR. Cadastrar como um USUÁRIO INVESTIDOR da Plataforma FCJ
      INVEST se tornará gratuito e não há nenhuma obrigação de investir ou de
      procurar captar investimentos. Fica totalmente a critério do USUÁRIO
      INVESTIDOR e ciente dos riscos atrelado a transação e de analisar
      previamente todos os riscos perante aquele investimento.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>1.4 </strong>Por meio da Plataforma InvestPlus, Investidores
      devidamente cadastrados e qualificados perante a InvestPlus poderão ter
      acesso a oportunidades de investimentos em Empresas, desde que cumpram com
      os requisitos estabelecidos pela plataforma.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>1.5 </strong>A plataforma é apresentada ao USUÁRIO
      INVESTIDOR&nbsp; da forma que se encontra disponível, tendo assim a FCJ
      INVEST comprometida para disponibilizar continua e permanentemente o
      devido acesso pelo primeiro, ficando sujeita, contudo, a eventos
      extraordinários, falhas, indisponibilidade da plataforma e entre outros
      problemas junto ao sistema de acesso à internet que ultrapassam a esfera
      da sua responsabilidade
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>1.6 </strong>Quando cadastrado o perfil de um USUÁRIO INVESTIDOR
      da InvestPlus, você terá acesso às áreas da Plataforma InvestPlus,
      hospedadas no domínio e subdomínios de https://www.investplus.vc e
      acessadas utilizando seu login e senha e com isto poderá ter acesso a seus
      investimentos e todo seu portfólio de rodada, acesso aos indicadores das
      empresas investidas.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>1.7 </strong>A Plataforma InvestPlus permite aos seus USUÁRIO
      INVESTIDOR conhecer empresas que faturam até R$ 10.000.000,00/ ano e que
      procuram investimentos online, visualizar as RODADAS DE INVESTIMENTOS
      dessas empresas e investir nessas empresas durante um período determinado,
      designado como o “RODADA”. O USUÁRIO INVESTIDOR da Plataforma InvestPlus
      que invista em/ empresas através da Plataforma InvestPlus são designados
      como “USUÁRIO INVESTIDOR”.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>1.8 </strong>O Usuário deve tomar a decisão de realizar o aporte
      na empresa investida de forma segura, ler os avisos de riscos e se tornar
      consciente de que qualquer investimento de equity crowdfunding envolve
      alto risco de perda do valor total do capital investido, as taxas de
      moralidade de Empresas, a falta de liquidez do valor mobiliário, os prazos
      de retorno do investimento e entre outras razões.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>1.9 </strong>As RODADAS DE INVESTIMENTOS publicadas na Plataforma
      InvestPlus são ofertas públicas de valores mobiliários dispensadas de
      registro e realizadas por emissores considerados sociedades empresárias de
      pequeno porte nos termos da Instrução CVM n.º 558/2017
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>1.10 </strong>O USUÁRIO INVESTIDOR poderá ter acesso na
      participação da Plataforma InvestPlus como INVESTIDOR e isto não impede
      sua participação também como EMPREENDEDOR e vice-versa.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>1.11 </strong>A InvestPlus avisará você sobre qualquer alteração
      relevante neste ACORDO por EMAIL com os dados cadastrados na Plataforma
      com pelo menos 12 (doze) dias de antecedência antes de tais alterações
      entrarem em vigor. Se você fizer objeção por escrito à alteração em até 12
      (doze) dias, essa discordância significará o término da sua associação à
      Plataforma InvestPlus, caso contrário consideraremos que houve sua
      aceitação formal.
    </p>
    <p></p>
    <p></p>
    <p>2. <strong>QUALIFICAÇÃO DO INVESTIDOR NA PLATAFORMA</strong></p>
    <p></p>
    <p></p>
    <p>
      <strong>2.1</strong> Importante destacar que para ter acesso a plataforma
      da InvestPlus, você deve ser:
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>a) </strong>pessoa física com inscrição válida no Cadastro de
      Pessoas Físicas do Ministério da Fazenda (“CPF”) e com pelo menos 18 anos
      de idade.
    </p>
    <p></p>
    <p></p>
    <p><strong>b) </strong>uma pessoa jurídica com sede ou filial no Brasil.</p>
    <p></p>
    <p></p>
    <p>
      <strong>c) </strong>um departamento, uma agência executiva ou uma entidade
      pública de um governo nacional, regional, local ou supranacional.
    </p>
    <p></p>
    <p></p>
    <p><strong>d) </strong>um fundo de investimento regulamentado pela CVM.</p>
    <p></p>
    <p></p>
    <p>
      <strong>2.2 </strong>O termo de regras gerais não estabelece nenhum
      contrato de sociedade e de mandato entre as partes .
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>2.3</strong> Caso você não queira mais ser um USUÁRIO INVESTIDOR
      da Plataforma InvestPlus, desde que observados os termos do CONTRATO DE
      ABERTURA DE RODADA e do ACORDO DE INVESTIMENTO porventura firmados, você
      pode terminar a sua associação a qualquer momento por aviso escrito
      enviado por e-mail para contato@fcjinvest.com , sendo certo que você
      continua obrigado aos compromissos firmados e aos termos desse ACORDO em
      relação a qualquer transação já feita através da plataforma InvestPlus.
    </p>
    <p></p>
    <p></p>
    <p>3.<strong>AS OPORTUNIDADES DE INVESTIMENTO</strong></p>
    <p></p>
    <p></p>
    <p>
      <strong>3.1</strong> Para o que importa a estas Regras Gerais,
      considerar-se-á Oportunidade de Investimento a realização pelo
      Empreendedor, por meio da plataforma eletrônica InvestPlus, de oferta
      pública de investimento (doravante “Investimento”), sem a participação de
      instituição intermediária e sem a elaboração de prospecto, automaticamente
      dispensada de registro perante a Comissão de Valores Mobiliários.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>3.2 </strong>A realização de Oferta Pública Direta na plataforma
      eletrônica InvestPlus pelo Empreendedor dependerá do cumprimento dos
      requisitos estabelecidos pela legislação em vigor à sua época.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>3.3 </strong>É responsabilidade única e exclusiva do Empreendedor
      a realização e a efetivação da Oferta Pública Direta e demais documentos,
      sendo de responsabilidade da InvestPlus somente a disponibilização da
      tecnologia necessária para viabilizar a apresentação da oportunidade de
      investimento aos investidores cadastrados na plataforma eletrônica.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>3.4</strong> É de responsabilidade exclusiva do Investidor a
      análise dos termos da Oferta Pública Direta e dos demais documentos e
      informações que a acompanharem, todos devidamente disponibilizados pelo
      Empreendedor por meio da Plataforma. Também caberá ao Investidor a
      aferição sobre o preenchimento dos requisitos mínimos para acesso à Oferta
      Pública Direta, nos termos determinados pelo Empreendedor.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>3.5</strong> Com a disponibilização da Oferta Pública Direta na
      plataforma, abrir-se-á prazo de até 60 (sessenta) dias para que os
      Investidores que cumpram os requisitos mínimos indiquem sua intenção de
      investimento.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>3.6</strong> Os documentos da Oferta Pública Direta, assim como as
      demais informações disponibilizadas pela plataforma, apontarão o período
      pelo qual deverá ser realizado o desembolso pelo Investidor em razão do
      investimento.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>3.7</strong> Caberá ao Investidor a realização do depósito dos
      recursos relativos ao investimento e a respectiva comprovação por meio de
      comunicação ao endereço eletrônico contato@fcjinvest.com .
    </p>
    <p></p>
    <p></p>
    <p>
      4.<strong>DIREITOS DE PROPRIEDADE INTELECTUAL E/OU INDUSTRIAL</strong>
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>4.1 </strong>A InvestPlus detém todos e quaisquer direitos sobre
      os projetos, logotipos, marcas, patentes, segredos de negócio, obras
      autorais bem como sobre quaisquer outros direitos de propriedade
      intelectual e/ou industrial que venham a ser criados, desenvolvidos ou
      modificados no âmbito da Plataforma.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>4.2 </strong>Nenhum direito de propriedade intelectual e/ou
      industrial, atualmente existente e de propriedade da InvestPlus que venha
      a ser facultado o uso e acesso na Plataforma às Empresas e Investidores,
      antes ou após seu cadastramento na Plataforma, será transferido ou
      licenciado de forma diversa da aqui prevista aos seus Usuários, a não ser
      que um contrato de licença de uso ou cessão de direitos seja assinado
      entre o interessado e a Plataforma.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>4.3 </strong>Às Empresas e Investidores devidamente cadastrados na
      Plataforma é licenciado o uso da Plataforma e dos serviços e ferramentas
      correlatos, conforme assim sejam contratados com a InvestPlus .
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>4.4 </strong>Salvo autorização expressa da InvestPlus , nenhum
      Usuário poderá copiar, modificar, distribuir, vender, alugar, publicar ou
      utilizar de forma diversa das aqui descrita os projetos, logotipos,
      marcas, patentes, segredos de negócio, obras autorais, bem como quaisquer
      outras obras de propriedade intelectual e/ou industrial, registrados ou
      não, pertencentes a InvestPlus, sob pena da aplicação das medidas legais
      cabíveis.
    </p>
    <p></p>
    <p></p>
    <p>5. <strong>DISPOSIÇÕES GERAIS</strong></p>
    <p></p>
    <p></p>
    <p>
      <strong>5.1 </strong>A tolerância do eventual descumprimento de quaisquer
      das cláusulas e condições do presente instrumento não constituirá novação
      das obrigações aqui estipuladas e tampouco impedirá ou inibirá a
      exigibilidade das mesmas a qualquer tempo.
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>5.2 </strong>Caso alguma disposição destes Termos de Uso seja
      considerada ilegítima por autoridade da localidade que o Usuário acessa a
      Plataforma ou que mantém vínculo, as demais condições permanecerão em
      pleno vigor e efeito até segunda ordem
    </p>
    <p></p>
    <p></p>
    <p>
      <strong>5.3 </strong>A InvestPlus poderá alterar os critérios de
      elegibilidade de sua Plataforma ou da realização de atividades nela a
      qualquer momento. Também, possui a faculdade de se recusar a conceder
      conta de acesso a qualquer pessoa ou entidade que tiver descumprido
      previamente os Termos de Uso ou documento legal da Plataforma e teve sua
      conta de acesso suspensa, excluída ou banida por esta razão.
    </p>
    <p></p>
    <p></p>
    <p>6. <strong>LEI APLICÁVEL E JURISDIÇÃO</strong></p>
    <p></p>
    <p></p>
    <p>
      <strong>6.1 </strong>Os Termos e Condições de Uso aqui descritos são
      interpretados segundo a legislação brasileira, no idioma português, sendo
      eleito o Foro da Comarca de Uberlândia no Estado de Minas Gerais para
      dirimir qualquer litígio, questão ou dúvida superveniente, com expressa
      renúncia de qualquer outro, por mais privilegiado que seja.
    </p>
    <p></p>
  </div>
</modal>
