<app-loader [loader]="loader"></app-loader>
<section class="content-header" style="margin-top: 2%;">
  <div class="row" *ngIf="!loader">
    <div class="col-xs-12 col-md-12">
      <button class="btn-upangel pull-right" (click)="exportAsXLSX()">Exportar <i
          class="fa fa-fw fa-download"></i></button>
    </div>
  </div>
</section>
<section class="content">
  <div *ngIf="!loader">
    <div class="row">
      <div class="col-xs-12">
        <div class="box">
          <div class="box-body table-responsive no-padding">
            <table class="table table-striped">
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>CPF/CNPJ</th>
                <th>RG</th>
                <th>Possui Investimento</th>
                <th>Cadastro</th>
              </tr>
              <tr *ngFor="let investor of investors | paginate: { itemsPerPage: 10, currentPage: p }">
                <td>{{ investor.fullName }}</td>
                <td>{{ investor.email }}</td>
                <td>{{ investor.phone | phone }}</td>
                <td>{{ investor.cpf | cpf }} {{ investor.cnpj | cnpj }}</td>
                <td>{{ investor.rg }}</td>
                <td>{{ investor.totalInvestedOthers > 0 ? 'S' : 'N' }}</td>
                <td>{{ investor.created | date:'dd/MM/yyyy' }}</td>
              </tr>
            </table>
            <app-count [count]="!(investors?.length > 0)" [textContador]="textRegister"></app-count>

            <div style="width: 100%; display: flex; justify-content: center; margin-top: 50px; align-items: center; gap: 32px;">
            <p (click)="currentPage === 1 ? null : changePage(currentPage - 1)" [ngStyle]="{'cursor': currentPage === 1 ? 'not-allowed' : 'pointer'}"
              style="font-family: Poppins, sans-serif; font-weight: 400; color: #909090; margin-bottom: 0; cursor: pointer;">
              Anterior
            </p>
              <div style="display: flex; align-items: center; gap: 15px;">
              <ng-container *ngIf="totalPages > 1">
                <div *ngFor="let page of getPaginationRange(totalPages); let i = index" 
                (click)="page !== '...' && changePage(page)"
                  style="width: 28px; height: 28px; display: flex; align-items: center; justify-content: center; border-radius: 4px; cursor: pointer; margin: 0 2px;">
                  <p [style.background-color]="page === currentPage ? '#035A7A' : 'transparent'"
                    [ngStyle]="{'color': page === currentPage ? '#FFFF' : 'inherit'}"
                    style="font-family: Poppins, sans-serif; font-weight: 400; margin-bottom: 0; width: 50px; padding: 5px; border-radius: 5px; text-align: center;">
                    {{ page }}
                  </p>
                </div>
              </ng-container>
              </div>
              <p 
                (click)="currentPage === totalPages ? null : changePage(currentPage + 1)"
                [ngStyle]="{'cursor': currentPage === totalPages ? 'not-allowed' : 'pointer'}"
                style="font-family: Poppins, sans-serif; font-weight: 500; color: #035A7A; margin-bottom: 0;">
                Próximo
              </p>
            </div>

            <div class="pagination-footer">
                <p>total: {{ totalInvestors }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
