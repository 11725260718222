<app-loader *ngIf="loader" [loader]="loader"></app-loader>
<form style="flex: 1;" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="content-desktop">
    <section class="content" *ngIf="!loader" style="width: 95%;">
      <div
        style="width: 100%; height: 120px; background: white; border-radius: 8px; margin-top: 30px; display: flex; align-items: center; padding: 0 16px;">
        <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
          <div style="display: flex; align-items: center;">
          <input type="file" id="fileInput" style="display: none;" (change)="onUploadChange($event)" accept="image/*">

          <img width="88px" height="88px" 
          style="border-radius: 50%; cursor: pointer; 
                 border: 2px solid #035A7A; /* Borda colorida */
                 box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); /* Sombra */
                 transition: transform 0.2s; /* Efeito de destaque no hover */
                " 
          src="{{ base64textString }}" 
          (click)="triggerFileInput()" 
          title="Selecione uma imagem com altura e largura iguais." 
          id="img" 
          />     

    
            <div style="margin-left: 32px;">
              <div>
                <h3 style="margin-left: 10px; font-size: 18px;">
                  <ng-container *ngIf="investor?.fullName">
                    <b>{{ investor.fullName.split(' ')[0] }}</b>
                    {{ investor.fullName.split(' ').slice(1).join(' ') }}
                  </ng-container>
                </h3>
                <p style="color: #505050;">@{{ investor?.nickname }}</p>
              </div>
    
              <div style="display: flex; align-items: center;">
                <p style="display: flex; align-items: center; gap: 8px;" *ngIf="investor?.publicProfile">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_358_2699)">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.00004 14.6673C11.6819 14.6673 14.6667 11.6826 14.6667 8.00065C14.6667 4.31875 11.6819 1.33398 8.00004 1.33398C4.31814 1.33398 1.33337 4.31875 1.33337 8.00065C1.33337 11.6826 4.31814 14.6673 8.00004 14.6673Z"
                        stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M1.33337 8H14.6667" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.00004 14.6673C9.47281 14.6673 10.6667 11.6826 10.6667 8.00065C10.6667 4.31875 9.47281 1.33398 8.00004 1.33398C6.52727 1.33398 5.33337 4.31875 5.33337 8.00065C5.33337 11.6826 6.52727 14.6673 8.00004 14.6673Z"
                        stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M3.28601 3.38086C4.49244 4.58729 6.15911 5.33349 8.00004 5.33349C9.84101 5.33349 11.5077 4.58729 12.7141 3.38086"
                        stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M12.7141 12.6186C11.5077 11.4122 9.84101 10.666 8.00004 10.666C6.15911 10.666 4.49244 11.4122 3.28601 12.6186"
                        stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_358_2699">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Perfil público
                </p>
                <p style="display: flex; align-items: center; gap: 8px;" *ngIf="!investor?.publicProfile">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_358_2699)">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.00004 14.6673C11.6819 14.6673 14.6667 11.6826 14.6667 8.00065C14.6667 4.31875 11.6819 1.33398 8.00004 1.33398C4.31814 1.33398 1.33337 4.31875 1.33337 8.00065C1.33337 11.6826 4.31814 14.6673 8.00004 14.6673Z"
                        stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M1.33337 8H14.6667" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.00004 14.6673C9.47281 14.6673 10.6667 11.6826 10.6667 8.00065C10.6667 4.31875 9.47281 1.33398 8.00004 1.33398C6.52727 1.33398 5.33337 4.31875 5.33337 8.00065C5.33337 11.6826 6.52727 14.6673 8.00004 14.6673Z"
                        stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M3.28601 3.38086C4.49244 4.58729 6.15911 5.33349 8.00004 5.33349C9.84101 5.33349 11.5077 4.58729 12.7141 3.38086"
                        stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path
                        d="M12.7141 12.6186C11.5077 11.4122 9.84101 10.666 8.00004 10.666C6.15911 10.666 4.49244 11.4122 3.28601 12.6186"
                        stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_358_2699">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  Perfil privado
                </p>
                <p>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_358_2731)">
                      <path
                        d="M8.00004 6.66732C9.47281 6.66732 10.6667 5.47342 10.6667 4.00065C10.6667 2.52789 9.47281 1.33398 8.00004 1.33398C6.52727 1.33398 5.33337 2.52789 5.33337 4.00065C5.33337 5.47342 6.52727 6.66732 8.00004 6.66732Z"
                        stroke="#035A7A" stroke-width="1.5" stroke-linejoin="round" />
                      <path d="M8 6.66602V12.666" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M5.33337 10.666H4.00004L1.33337 14.666H14.6667L12 10.666H10.6667" stroke="#035A7A"
                        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_358_2731">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {{
                  descriptionCity == null
                  ? "Sem informação"
                  : descriptionCity
                  }}
                </p>
                <p>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.66663 6.33398H14.3333V13.334C14.3333 13.7022 14.0348 14.0007 13.6666 14.0007H2.33329C1.9651 14.0007 1.66663 13.7022 1.66663 13.334V6.33398Z"
                      stroke="#035A7A" stroke-width="1.5" stroke-linejoin="round" />
                    <path
                      d="M1.66663 3.00065C1.66663 2.63246 1.9651 2.33398 2.33329 2.33398H13.6666C14.0348 2.33398 14.3333 2.63246 14.3333 3.00065V6.33398H1.66663V3.00065Z"
                      stroke="#035A7A" stroke-width="1.5" stroke-linejoin="round" />
                    <path d="M5.33337 1.33398V4.00065" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M10.6666 1.33398V4.00065" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M9.33337 11.334H11.3334" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M4.66663 11.334H6.66663" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M9.33337 8.66602H11.3334" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M4.66663 8.66602H6.66663" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  {{
                  descriptionDate == null
                  ? "Sem informação"
                  : "Nasceu em " + descriptionDate
                  }}
                </p>
                <p>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_358_2744)">
                      <path
                        d="M14.3643 3.99249L12.0072 1.63547C11.7469 1.37512 11.3248 1.37512 11.0644 1.63547L7.76459 4.9353C7.50424 5.19565 7.50424 5.61776 7.76459 5.87811L10.1216 8.23513C10.382 8.49548 10.8041 8.49548 11.0644 8.23513L14.3643 4.9353C14.6246 4.67495 14.6246 4.25284 14.3643 3.99249Z"
                        stroke="#035A7A" stroke-width="1.5" stroke-linejoin="round" />
                      <path
                        d="M8.23602 10.1214L5.879 7.76437C5.61865 7.50402 5.19654 7.50402 4.93619 7.76437L1.63636 11.0642C1.37601 11.3246 1.37601 11.7467 1.63636 12.007L3.99338 14.364C4.25373 14.6244 4.67584 14.6244 4.93619 14.364L8.23602 11.0642C8.49637 10.8039 8.49637 10.3817 8.23602 10.1214Z"
                        stroke="#035A7A" stroke-width="1.5" stroke-linejoin="round" />
                      <path d="M10.3574 5.64258L5.64337 10.3566" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_358_2744">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {{
                  descriptionSite == null
                  ? "Sem informação"
                  : descriptionSite
                  }}
                </p>
              </div>
            </div>
          </div>
    
          <div>
            <div>
              <p style="gap: 16px; display: flex;">
                <span class="social {{ facebook == null ? 'isDisabled' : '' }}"><a
                    href="{{ facebook == null ? '#' : facebook }}" target="_blank">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_358_1873)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M1.37036 15.3327C0.981717 15.3327 0.666656 15.0159 0.666656 14.6251V1.3736C0.666656 0.982809 0.981717 0.666016 1.37036 0.666016H14.6296C15.0183 0.666016 15.3333 0.982809 15.3333 1.3736V14.6251C15.3333 15.0159 15.0183 15.3327 14.6296 15.3327C14.1605 15.3327 9.74072 15.3327 1.37036 15.3327Z"
                          stroke="#035A7A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12.8872 5.96319H11.4359H11.2644C10.8144 5.96319 10.4496 6.32803 10.4496 6.77803V8.40813H12.8872L12.5302 10.8517H10.4496V15.3342H7.7689V10.8517H5.55566V8.40813H7.7403L7.7689 6.08866L7.76093 5.25736C7.74966 4.08346 8.69213 3.1227 9.86603 3.11142C9.87283 3.11136 9.87963 3.11133 9.88643 3.11133H12.8872V5.96319Z"
                          fill="#035A7A" />
                      </g>
                      <defs>
                        <clipPath id="clip0_358_1873">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg></a>
                </span>
                <span class="social {{ linkedin == null ? 'isDisabled' : '' }}"><a
                    href="{{ linkedin == null ? '#' : linkedin }}" target="_blank">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_358_1876)">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.00001 14.6673C11.6819 14.6673 14.6667 11.6826 14.6667 8.00065C14.6667 4.31875 11.6819 1.33398 8.00001 1.33398C4.31811 1.33398 1.33334 4.31875 1.33334 8.00065C1.33334 11.6826 4.31811 14.6673 8.00001 14.6673Z"
                          stroke="#035A7A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1.33334 8H14.6667" stroke="#035A7A" stroke-width="1.66667" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.00001 14.6673C9.47278 14.6673 10.6667 11.6826 10.6667 8.00065C10.6667 4.31875 9.47278 1.33398 8.00001 1.33398C6.52724 1.33398 5.33334 4.31875 5.33334 8.00065C5.33334 11.6826 6.52724 14.6673 8.00001 14.6673Z"
                          stroke="#035A7A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M3.28597 3.38086C4.4924 4.58729 6.15907 5.33349 8 5.33349C9.84097 5.33349 11.5076 4.58729 12.7141 3.38086"
                          stroke="#035A7A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                          d="M12.7141 12.6186C11.5076 11.4122 9.84098 10.666 8.00001 10.666C6.15908 10.666 4.49241 11.4122 3.28598 12.6186"
                          stroke="#035A7A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_358_1876">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a></span>
                <span class="social {{ twitter == null ? 'isDisabled' : '' }}"><a
                    href="{{ twitter == null ? '#' : twitter }}" target="_blank">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1.66666 11.9214C2.30961 12.2768 6.96379 14.9597 10.2733 12.892C13.5828 10.8242 13.4002 7.26175 13.4002 5.62932C13.7 5.00125 14.3333 4.68195 14.3333 2.98192C13.7112 3.55658 13.0929 3.75212 12.4783 3.56852C11.8762 2.65051 11.0478 2.24447 9.99312 2.35043C8.41109 2.50936 7.83229 4.06148 8.00262 6.06955C5.56329 7.30312 3.65049 5.17532 2.66472 3.56852C2.33535 4.83395 2.01776 6.35318 2.66472 8.03382C3.09602 9.15422 4.13282 10.1014 5.77512 10.8755C4.11076 11.7776 2.74126 12.1262 1.66666 11.9214Z"
                        stroke="#035A7A" stroke-width="1.66667" stroke-linejoin="round" />
                    </svg>
                  </a></span>
              </p>
            </div>
    
            <!-- <div (click)="onDevelopmentToast()" class="editable-profile">
              <p style="color: #035A7A; font-size: 14px; margin-bottom: 0;">Editar perfil</p>
            </div> -->
          </div>
        </div>
      </div>
      <div
        style="width: 100%; height: 705px; background: white; border-radius: 8px; margin-top: 30px; padding: 24px 16px; margin-bottom: 50px; overflow-y: auto;">
        <div style="width: 100%; display: flex; gap: 32px; ">
          <p 
          style="white-space: nowrap;"
          *ngFor="let tab of tabs; let i = index"
            [ngClass]="{'active-tab': activeTab === i, 'inactive-tab': activeTab !== i}" (click)="setActiveTab(i)">
            {{ tab }}
          </p>
        </div>
    
        <div style="margin-top: 32px; border-bottom: 1px solid #E5E5E5;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">{{ tabs[activeTab] }}</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">{{ tabContents[activeTab] }}</p>
        </div>
    
        <div *ngIf="activeTab === 0">
          <div *ngIf="!investor?.cnpj" style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; align-items: center; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">Profissão</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Minha profissão</p>
            </div>
        
            <div style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px; margin-bottom: 10px;">
              <input type="text" formControlName="profession"
                style="height: 100%; width: 100%; background-color: #FBFBFB; border: none; padding: 0 16px; outline: none;"
                placeholder="Minha profissão" />        
            </div>          
          </div>
        
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; width: 100%;">
            <div style="width: 30%; display: flex; flex-direction: column; align-items: start;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">{{ investor?.cnpj ? 'Dados da Empresa' : 'Pessoal' }}</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">{{ investor?.cnpj ? 'Informações da Empresa' : 'Informações pessoais' }}</p>
            </div>
        
            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 16px;">
              <app-form [label]="investor?.cnpj ? 'Data de Fundação' : 'Data de nascimento'" 
                        [errorMessage]="investor?.cnpj ? 'Data de Fundação é um campo obrigatório.' : 'Data de nascimento é um campo obrigatório.'">
                <input type="text" (input)="formatDate()" (blur)="validateDate()" formControlName="dateOfBirth"
                  style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                  [placeholder]="investor?.cnpj ? 'Data de Fundação' : 'Data de nascimento'" />
              </app-form>
        
              <app-form [label]="investor?.cnpj ? 'País de Fundação da empresa' : 'Nacionalidade'" [errorMessage]="'Nacionalidade é um campo obrigatório.'">
                <select formControlName="nationality"
                  style="height: 50px; width: 240px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
                  <option [ngValue]="null">Selecione uma opção...</option>
                  <option value="BRASILEIRO">Brasileiro</option>
                  <option value="AMERICANO">Americano</option>
                  <option value="ANTIGUANO">Antiguano</option>
                  <option value="ARGENTINO">Argentino</option>
                  <option value="BAHAMENSE">Bahamense</option>
                  <option value="BARBADIANO">Barbadiano</option>
                  <option value="BELIZENHO">Belizenho</option>
                  <option value="BOLIVIANO">Boliviano</option>
                  <option value="CHILENO">Chileno</option>
                  <option value="COLOMBIANO">Colombiano</option>
                  <option value="COSTARRIQUENHO">Costarriquenho</option>
                  <option value="CUBANO">Cubano</option>
                  <option value="DOMINICANO">Dominicano</option>
                  <option value="EQUATORIANO">Equatoriano</option>
                  <option value="SALVADORENHO">Salvadorenho</option>
                  <option value="GRANADINO">Granadino</option>
                  <option value="GUATEMALTECO">Guatemalteco</option>
                  <option value="GUIANÊS">Guianês</option>
                  <option value="GUIANENSE">Guianense</option>
                  <option value="HAITIANO">Haitiano</option>
                  <option value="HONDURENHO">Hondurenho</option>
                  <option value="JAMAICANO">Jamaicano</option>
                  <option value="MEXICANO">Mexicano</option>
                  <option value="NICARAGUENSE">Nicaraguense</option>
                  <option value="PANAMENHO">Panamenho</option>
                  <option value="PARAGUAIO">Paraguaio</option>
                  <option value="PERUANO">Peruano</option>
                  <option value="PORTORRIQUENHO">Portorriquenho</option>
                  <option value="DOMINICANA">Dominicana</option>
                  <option value="SÃO-CRISTOVENSE">São-cristovense</option>
                  <option value="SÃO-VICENTINO">São-vicentino</option>
                  <option value="SANTA-LUCENSE">Santa-lucense</option>
                  <option value="SURINAMÊS">Surinamês</option>
                  <option value="TRINDADENSE">Trindadense</option>
                  <option value="URUGUAIO">Uruguaio</option>
                  <option value="VENEZUELANO">Venezuelano</option>
                  <option value="ALEMÃO">Alemão</option>
                  <option value="AUSTRÍACO">Austríaco</option>
                  <option value="BELGA">Belga</option>
                  <option value="CROATA">Croata</option>
                  <option value="DINAMARQUÊS">Dinamarquês</option>
                  <option value="ESLOVACO">Eslovaco</option>
                  <option value="ESLOVENO">Esloveno</option>
                  <option value="ESPANHOL">Espanhol</option>
                  <option value="FRANCÊS">Francês</option>
                  <option value="GREGO">Grego</option>
                  <option value="HÚNGARO">Húngaro</option>
                  <option value="IRLANDÊS">Irlandês</option>
                  <option value="ITALIANO">Italiano</option>
                  <option value="NORUEGO">Noruego</option>
                  <option value="HOLANDÊS">Holandês</option>
                  <option value="POLONÊS">Polonês</option>
                  <option value="PORTUGUÊS">Português</option>
                  <option value="BRITÂNICO">Britânico</option>
                  <option value="INGLÊS">Inglês</option>
                  <option value="GALÊS">Galês</option>
                  <option value="ESCOCÊS">Escocês</option>
                  <option value="ROMENO">Romeno</option>
                  <option value="RUSSO">Russo</option>
                  <option value="SÉRVIO">Sérvio</option>
                  <option value="SUECO">Sueco</option>
                  <option value="SUÍÇO">Suíço</option>
                  <option value="TURCO">Turco</option>
                  <option value="UCRANIANO">Ucraniano</option>
                  <option value="CANADENSE">Canadense</option>
                  <option value="ANGOLANO">Angolano</option>
                  <option value="MOÇAMBICANO">Moçambicano</option>
                  <option value="SUL-AFRICANO">Sul-africano</option>
                  <option value="ZIMBABUENSE">Zimbabuense</option>
                  <option value="ARGÉLIA">Argélia</option>
                  <option value="COMORENSE">Comorense</option>
                  <option value="EGÍPCIO">Egípcio</option>
                  <option value="LÍBIO">Líbio</option>
                  <option value="MARROQUINO">Marroquino</option>
                  <option value="GANÉS">Ganés</option>
                  <option value="QUENIANO">Queniano</option>
                  <option value="RUANDÊS">Ruandês</option>
                  <option value="UGANDENSE">Ugandense</option>
                  <option value="BECHUANO">Bechuano</option>
                  <option value="MARFINENSE">Marfinense</option>
                  <option value="CAMARONENSE">Camaronense</option>
                  <option value="NIGERIANO">Nigeriano</option>
                  <option value="SOMALI">Somali</option>
                  <option value="AUSTRALIANO">Australiano</option>
                  <option value="NEOZELANDÊS">Neozelandês</option>
                  <option value="AFEGÃO">Afegão</option>
                  <option value="SAUDITA">Saudita</option>
                  <option value="ARMENO">Armeno</option>
                  <option value="BANGLADESH">Bangladesh</option>
                  <option value="CHINÊS">Chinês</option>
                  <option value="NORTE-COREANO">Norte-coreano</option>
                  <option value="SUL-COREANO">Sul-coreano</option>
                  <option value="INDIANO">Indiano</option>
                  <option value="INDONÉSIO">Indonésio</option>
                  <option value="IRAQUIANO">Iraquiano</option>
                  <option value="IRANIANO">Iraniano</option>
                  <option value="ISRAELITA">Israelita</option>
                  <option value="JAPONÊS">Japonês</option>
                  <option value="MALAIO">Malaio</option>
                  <option value="NEPALÊS">Nepalês</option>
                  <option value="OMANENSE">Omanense</option>
                  <option value="PAQUISTANÊS">Paquistanês</option>
                  <option value="PALESTINO">Palestino</option>
                  <option value="QATARENSE">Qatarense</option>
                  <option value="SÍRIO">Sírio</option>
                  <option value="CINGALÊS">Cingalês</option>
                  <option value="TAILANDÊS">Tailandês</option>
                  <option value="TIMORENSE">Timorense</option>
                  <option value="ÁRABE">Árabe</option>
                  <option value="VIETNAMITA">Vietnamita</option>
                  <option value="IEMENITA">Iemenita</option>
                </select>
              </app-form>
            
            </div>
          </div>
    
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; width: 100%;">
            <div style="width: 30%; display: flex; flex-direction: column; align-items: start;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">Identificação</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">{{ investor?.cnpj ? 'Detalhes de Identificação do Sócio Representante' : 'Detalhes de Identificação' }}</p>
            </div>
    
            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 16px;">
              <app-form [label]="investor?.cnpj ? 'Número do RG do Sócio Representante' : 'Número do RG'" [errorMessage]="'Número do RG é um campo obrigatório.'">
                <input type="text" formControlName="rg"
                  style="height: 50px; width: 240px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                  maxlength="13" [placeholder]="investor?.cnpj ? 'Número do RG do Sócio Representante' : 'Número do RG'" />
              </app-form>
    
              <app-form style="margin-left: -1%;" [label]="'Orgão emissor'" [errorMessage]="'Orgão emissor é um campo obrigatório.'">
                <select formControlName="rgEmitter"
                  style="height: 50px; width: 240px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
                  <option [ngValue]="null">Selecione uma opção...</option>
                  <option value="SSP">SSP</option>
                  <option value="SSP-AC">SSP-AC</option>
                  <option value="SSP-AL">SSP-AL</option>
                  <option value="SSP-AM">SSP-AM</option>
                  <option value="SSP-AP">SSP-AP</option>
                  <option value="SSP-BA">SSP-BA</option>
                  <option value="SSP-CE">SSP-CE</option>
                  <option value="SSP-DF">SSP-DF</option>
                  <option value="SSP-ES">SSP-ES</option>
                  <option value="SSP-GO">SSP-GO</option>
                  <option value="SSP-MA">SSP-MA</option>
                  <option value="SSP-MG">SSP-MG</option>
                  <option value="SSP-MS">SSP-MS</option>
                  <option value="SSP-MT">SSP-MT</option>
                  <option value="SSP-PA">SSP-PA</option>
                  <option value="SSP-PB">SSP-PB</option>
                  <option value="SSP-PE">SSP-PE</option>
                  <option value="SSP-PI">SSP-PI</option>
                  <option value="SSP-PR">SSP-PR</option>
                  <option value="SSP-RJ">SSP-RJ</option>
                  <option value="SSP-RN">SSP-RN</option>
                  <option value="SSP-RO">SSP-RO</option>
                  <option value="SSP-RR">SSP-RR</option>
                  <option value="SSP-RS">SSP-RS</option>
                  <option value="SSP-SC">SSP-SC</option>
                  <option value="SSP-SE">SSP-SE</option>
                  <option value="SSP-SP">SSP-SP</option>
                  <option value="SSP-TO">SSP-TO</option>
                  <option value="CREA">CREA</option>
                  <option value="MAE">MAE</option>
                  <option value="IML">IML</option>
                  <option value="OAB">OAB</option>
                  <option value="CARTORIO">Cartório Civil</option>
                  <option value="DETRAN">DETRAN</option>
                  <option value="FEDERAL">Polícia Federal</option>
                </select>
              </app-form>
        
              <app-form [label]="investor?.cnpj ? 'Telefone do Sócio Representante' : 'Telefone'" [errorMessage]="'Telefone é um campo obrigatório.'">
                <input type="text" (input)="formatPhone()" formControlName="phone"
                  style="height: 50px; width: 240px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                  [placeholder]="investor?.cnpj ? 'Telefone do Sócio Representante' : 'Telefone'" />
              </app-form>

              <app-form *ngIf="investor?.cnpj == undefined" [label]="'CPF do investidor'"
              [errorMessage]="'CPF do investidor é um campo obrigatório.'">
              <input type="text" formControlName="cpfResponsible"
                style="height: 50px; width: 245px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                placeholder="CPF do investidor" />
              </app-form>

              <app-form *ngIf="investor?.cnpj == undefined" [label]="investor?.cnpj ? 'Estado civil do sócio Representante' : 'Estado civil'" [errorMessage]="'Estado civil é um campo obrigatório.'">
                <select formControlName="maritalStatus"
                  style="height: 50px; width: 240px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
                  <option [ngValue]="null">Selecione uma opção...</option>
                  <option value="SINGLE">Solteiro</option>
                  <option value="MARRIED">Casado</option>
                  <option value="SEPARATED">Separado</option>
                  <option value="DIVORCED">Divorciado</option>
                  <option value="WIDOWED">Viúvo</option>
                </select>
              </app-form>

              <app-form *ngIf="investor?.cnpj == undefined" [label]="investor?.cnpj ? 'Sexo do Sócio Representante' : 'Sexo'"  [errorMessage]="'Sexo é um campo obrigatório.'">
                <select formControlName="gender"
                  style="height: 50px; width: 240px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
                  <option [ngValue]="null">Selecione uma opção...</option>
                  <option value="MALE">Masculino</option>
                  <option value="FEMALE">Feminino</option>
                </select>
              </app-form>

            </div>
          </div>
        
          <div 
            *ngIf="investor?.cnpj !== undefined"
            style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; align-items: center; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">Sócio Representante</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Informações do sócio Representante</p>
            </div>
          
            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 16px;">
              <app-form [label]="'Nome do sócio Representante'"
                [errorMessage]="'Nome do sócio Representante é um campo obrigatório.'">
                <input type="text" formControlName="nameResponsible"
                  style="height: 50px; width: 245px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                  placeholder="Nome do sócio Representante" />
              </app-form>
          
              <app-form [label]="'CPF do sócio Representante'"
                [errorMessage]="'CPF do sócio Representante é um campo obrigatório.'">
                <input type="text" formControlName="cpfResponsible"
                  style="height: 50px; width: 245px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                  placeholder="CPF do sócio Representante" />
              </app-form>

              <app-form 
              [label]="investor?.cnpj ? 'Estado civil do sócio Representante' : 'Estado civil'" 
              [errorMessage]="'Estado civil é um campo obrigatório.'">
              
              <select formControlName="maritalStatus"
                style="height: 50px; width: 240px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
                <option [ngValue]="null">Selecione uma opção...</option>
                <option value="SINGLE">Solteiro</option>
                <option value="MARRIED">Casado</option>
                <option value="SEPARATED">Separado</option>
                <option value="DIVORCED">Divorciado</option>
                <option value="WIDOWED">Viúvo</option>
              </select>
              </app-form>

              <app-form [label]="investor?.cnpj ? 'Sexo do Sócio Representante' : 'Sexo'"  [errorMessage]="'Sexo é um campo obrigatório.'">
                <select formControlName="gender"
                  style="height: 50px; width: 240px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
                  <option [ngValue]="null">Selecione uma opção...</option>
                  <option value="MALE">Masculino</option>
                  <option value="FEMALE">Feminino</option>
                </select>
              </app-form>

            </div>
          </div>
        </div>        
    
        <div *ngIf="activeTab === 1">

          <div *ngIf="isPJ" style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; align-items: flex-start; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">
                Endereço Fiscal da Empresa
              </h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">
                Detalhes do endereço fiscal da empresa
              </p>
            </div>
            <div style="width: 70%;">
        
              <app-form [label]="'CEP'" [errorMessage]="'CEP da empresa é um campo obrigatório.'">
                <input type="text" (input)="onCepInputPJ()" formControlName="zipcodepj"
                  style="height: 50px; width: 300px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                  placeholder="CEP" />
              </app-form>
        
              <app-form [label]="'Logradouro'" [errorMessage]="'Logradouro da empresa é um campo obrigatório.'">
                <input type="text" formControlName="streetpj"
                  style="height: 50px; width: 400px; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                  placeholder="Logradouro" />
              </app-form>
        
              <div style="display: flex; align-items: center; gap: 15px;">
                <app-form [label]="'Número'" [errorMessage]="'Número da empresa é um campo obrigatório.'">
                  <input type="text" formControlName="numberpj"
                    style="height: 50px; width: 195px; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                    placeholder="Número" />
                </app-form>
        
                <app-form [label]="'Bairro'" [errorMessage]="'Bairro da empresa é um campo obrigatório.'">
                  <input type="text" formControlName="neighborhoodpj"
                    style="height: 50px; width: 195px; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                    placeholder="Bairro" />
                </app-form>
              </div>
        
              <div style="display: flex; align-items: center; gap: 15px;">
                <app-form [label]="'Cidade'" [errorMessage]="'Cidade da empresa é um campo obrigatório.'">
                  <input type="text" formControlName="citypj"
                    style="height: 50px; width: 195px; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                    placeholder="Cidade" />
                </app-form>
        
                <app-form [label]="'Estado'" [errorMessage]="'Estado da empresa é um campo obrigatório.'">
                  <select 
                    formControlName="ufpj"
                    style="height: 50px; width: 195px; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
                    <option [ngValue]="null">Selecione uma opção...</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </app-form>
              </div>
            </div>
          </div>
        
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; align-items: flex-start; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">
                {{ isPJ ? 'Endereço do Sócio Representante' : 'Detalhes' }}
              </h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">
                {{ isPJ ? 'Endereço do sócio Representante' : 'Detalhes do endereço' }}
              </p>
            </div>
            <div style="width: 70%;">
        
              <app-form [label]="'CEP'" [errorMessage]="'CEP é um campo obrigatório.'">
                <input type="text" (input)="onCepInput()" formControlName="zipCode"
                  style="height: 50px; width: 300px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                  placeholder="CEP" />
              </app-form>
        
              <app-form [label]="'Logradouro'" [errorMessage]="'Logradouro é um campo obrigatório.'">
                <input type="text" formControlName="street"
                  style="height: 50px; width: 400px; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                  placeholder="Logradouro" />
              </app-form>
        
              <div style="display: flex; align-items: center; gap: 15px;">
                <app-form [label]="'Número'" [errorMessage]="'Número é um campo obrigatório.'">
                  <input type="text" formControlName="number"
                    style="height: 50px; width: 195px; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                    placeholder="Número" />
                </app-form>
        
                <app-form [label]="'Bairro'" [errorMessage]="'Bairro é um campo obrigatório.'">
                  <input type="text" formControlName="neighborhood"
                    style="height: 50px; width: 195px; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                    placeholder="Bairro" />
                </app-form>
              </div>
        
              <app-form [label]="'Complemento'" [errorMessage]="'Complemento é um campo obrigatório.'">
                <input type="text" formControlName="complement"
                  style="height: 50px; width: 400px; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                  placeholder="Complemento" />
              </app-form>
        
              <div style="display: flex; align-items: center; gap: 15px;">
                <app-form [label]="'Cidade'" [errorMessage]="'Cidade é um campo obrigatório.'">
                  <input type="text" formControlName="city"
                    style="height: 50px; width: 195px; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                    placeholder="Cidade" />
                </app-form>
        
                <app-form [label]="'Estado'" [errorMessage]="'Estado é um campo obrigatório.'">
                  <select 
                    formControlName="uf"
                    style="height: 50px; width: 195px; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
                    <option [ngValue]="null">Selecione uma opção...</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </app-form>
              </div>
            </div>
          </div>
        </div>        
    
        <div *ngIf="activeTab === 2">
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">Investimentos</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Conforme exigido pela Instrução
                CVM 588/2017.</p>
            </div>
    
    
            <app-radio [options]="investorProfileStatements" formControlName="investorProfileStatement">
            </app-radio>
          </div>
    
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">Declaração dos Valores Investidos no Ano-Calendário *
              </h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">
                Conforme exigido pela Instrução CVM 588/2017.
                Somente incluir o montante já investido no ano-
                calendário em ofertas dispensadas de registro nos
                termos da Instrução CVM 588/2017 por meio de
                plataformas eletrônicas de investimento participativo.
              </p>
            </div>
    
            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 16px; margin-left: 10px;">
              <app-form [label]="'Na plataforma da InvestPlus'" [errorMessage]="'Plataforma é um campo obrigatório.'">
                <div class="input-group">
                  <input type="text" formControlName="investedUpangel"
                    style="height: 50px; width: 240px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                    disabled placeholder="Na plataforma da InvestPlus" />
                </div>
              </app-form>
    
              <app-form [label]="'Outras plataformas'" [errorMessage]="'Outras plataformas é um campo obrigatório.'">
                <div class="input-group">
                  <input (keyup)="calculateInvestment(form.value.totalInvestedOthers)" type="text"
                    formControlName="totalInvestedOthers"
                    style="height: 50px; width: 240px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                    maxlength="13" placeholder="Outras plataformas" />
                </div>
              </app-form>
    
              <app-form [label]="'Total'" [errorMessage]="'Total é um campo obrigatório.'">
                <div class="input-group">
                  <input [(ngModel)]="investmentValue" type="text" formControlName="totalInvested"
                    style="height: 50px; width: 240px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                    disabled placeholder="Total" />
                </div>
              </app-form>
            </div>
          </div>
    
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">Declaração pessoa politicamente exposta</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">
                Considera-se aquela que desempenha ou tenha
                desempenhado, nos últimos 5 (cinco) anos, cargos,
                empregos ou funções públicas relevantes, no Brasil ou em
                outros países, territórios e dependências estrangeiras,
                assim como seus representantes, familiares e outras
                pessoas de seu relacionamento próximo.
              </p>
            </div>
    
            <app-radio style="display: flex; gap: 16px;" [options]="publicFigures" formControlName="publicFigure">
    
            </app-radio>
          </div>
        </div>
    
        <div *ngIf="activeTab === 3">
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; align-items: center; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">Website</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Meu website</p>
            </div>
    
            <app-form [label]="'Website'" [errorMessage]="'Website é um campo obrigatório.'">
              <input type="text" formControlName="personalWebsite"
                style="height: 50px; width: 500px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                placeholder="Website" />
            </app-form>
          </div>
    
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; align-items: center; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">facebook</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Meu facebook</p>
            </div>
    
            <app-form [label]="'Facebook'" [errorMessage]="'Facebook é um campo obrigatório.'">
              <input type="text" formControlName="facebook"
                style="height: 50px; width: 500px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                placeholder="Facebook" />
            </app-form>
          </div>
    
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; align-items: center; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">Twitter</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Meu twitter</p>
            </div>
    
            <app-form [label]="'Twitter'" [errorMessage]="'Twitter é um campo obrigatório.'">
              <input type="text" formControlName="twitter"
                style="height: 50px; width: 500px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                placeholder="Twitter" />
            </app-form>
          </div>
    
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; align-items: center; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">Linkedin</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Meu linkedin</p>
            </div>
    
            <app-form [label]="'Linkedin'" [errorMessage]="'Linkedin é um campo obrigatório.'">
              <input type="text" formControlName="linkedin"
                style="height: 50px; width: 500px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                placeholder="Linkedin" />
            </app-form>
          </div>
    
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; align-items: center; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">InvestPlus</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Por onde conheceu a InvestPlus?
              </p>
            </div>
    
            <div>
              <app-form [label]="'Como você soube da InvestPlus'"
                [errorMessage]="'Como soube da InvestPlus é um campo obrigatório.'">
                <select formControlName="aboutUpangel"
                  style="height: 50px; width: 500px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
                  <option [ngValue]="null">Selecione uma opção...</option>
                  <option value="INDICACAO">Uma indicação</option>
                  <option value="EVENTO">Um evento</option>
                  <option value="PESSOAL">Um encontro pessoal</option>
                  <option value="IMPRENSA">Na imprensa</option>
                  <option value="GOOGLE">Pela pesquisa Google</option>
                  <option value="ONLINE">Por outra pesquisa online</option>
                  <option value="FACEBOOK">Facebook</option>
                  <option value="INSTAGRAM">Instagram</option>
                  <option value="LINKEDIN">Linkedin</option>
                  <option value="TWITTER">Twitter</option>
                  <option value="PUBLICIDADE">Publicidade</option>
                  <option value="OUTRO">Outro</option>
                </select>
              </app-form>
    
              <app-form [label]="'Código do agente indicador'" [errorMessage]="'Agente indicador é um campo obrigatório.'">
                <input type="text" formControlName="agent"
                  style="height: 50px; width: 500px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                  maxlength="4" placeholder="Código do agente indicador" />
              </app-form>
            </div>
          </div>
        </div>
    
        <div *ngIf="activeTab === 4">
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; align-items: center; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">Conta</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Minha conta</p>
            </div>
    
            <div>
              <app-form [label]="'Instituição'" [errorMessage]="'Instituição é um campo obrigatório.'">
                <select formControlName="accountBank"
                  style="height: 50px; width: 500px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                  placeholder="Instituição">
                  <option [ngValue]="null">Selecione uma opção...</option>
                  <ng-container *ngFor="let bank of banks">
                    <option [value]="bank?.code">{{ bank?.name }}</option>
                  </ng-container>
                </select>
              </app-form>
              <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 16px;">
                <app-form [label]="'Agência'" [errorMessage]="'Agência é um campo obrigatório.'">
                  <input type="text" formControlName="accountAgency"
                    style="height: 50px; width: 245px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                    placeholder="Agência" />
                </app-form>
    
                <app-form [label]="'Conta Corrente (com dígito)'" [errorMessage]="'Conta é um campo obrigatório.'">
                  <input type="text" formControlName="accountNumber"
                    style="height: 50px; width: 245px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                    placeholder="Conta Corrente (com dígito)" />
                </app-form>
              </div>
            </div>
    
    
          </div>
    
          <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; align-items: center; width: 100%;">
            <div style="width: 30%;">
              <h3 style="font-weight: 600; margin-bottom: 12px;">Perfil público?</h3>
              <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Tornar o perfil básico público?
              </p>
            </div>
    
            <legend class="margin-t-20">
              <input type="checkbox" formControlName="publicProfile" class="flat-red" />
              Com um perfil público, outros usuários registrados na
              Plataforma InvestPlus conseguirão ver a sua foto.
            </legend>
          </div>
        </div>

        <div *ngIf="activeTab === 5" formArrayName="admins" style="margin-bottom: 80px;">

          <div *ngIf="admins.controls.length === 0" style="text-align: center; color: #999; margin: 16px 0;">
            Nenhum administrador adicionado. Clique em "Adicionar Administrador" para começar.
            
            <button type="button" (click)="addAdmin()" 
              style="display: block; margin: 16px auto 0; height: 50px; background-color: #007bff; color: white; font-weight: bold; border: none; border-radius: 4px; padding: 0 16px;">
              Adicionar Administrador
            </button>
          </div>
          
          <div *ngFor="let admin of admins.controls; let i = index" [formGroupName]="i" style="margin-top: 16px; padding: 16px; border: 1px solid #E5E5E5; border-radius: 4px;">
            <h3 style="font-weight: 600; font-size: 18px; margin-bottom: 12px;">
              Administrador {{ i + 1 }}{{ admin.get('fullName').value ? ' - ' + admin.get('fullName').value : '' }}
            </h3>            
        
            <app-form [label]="'Nome Completo'" [errorMessage]="'Nome Completo é obrigatório.'">
              <input formControlName="fullName" placeholder="Nome Completo" 
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;" />
            </app-form>

            <app-form [label]="'Email'" [errorMessage]="'Email é obrigatório.'">
              <input formControlName="email" placeholder="Email" 
                type="email"
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;" />
            </app-form>            
        
            <app-form [label]="'CPF'" [errorMessage]="'CPF é obrigatório.'">
              <input formControlName="cpfCnpj" placeholder="CPF"
                (input)="applyCpfMask($event)" maxlength="14"
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;" />
            </app-form>
        
            <app-form [label]="'RG'" [errorMessage]="'RG é obrigatório.'">
              <input formControlName="rg" placeholder="RG" 
                (input)="applyRgMask($event)" maxlength="12" 
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;" />
            </app-form>
        
            <app-form [label]="'Data de Nascimento'" [errorMessage]="'Data de Nascimento é obrigatória.'">
              <input formControlName="dateOfBirth" placeholder="Data de Nascimento" 
                (input)="applyDateMask($event)" maxlength="10" 
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;" />
            </app-form>
        
            <app-form [label]="'Telefone'" [errorMessage]="'Telefone é obrigatório.'">
              <input formControlName="phone" placeholder="Telefone" 
                (input)="applyPhoneMask($event)" maxlength="15" 
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;" />
            </app-form>
        
            <app-form [label]="'Gênero'" [errorMessage]="'Gênero é obrigatório.'">
              <select formControlName="gender" 
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;">
                <option [ngValue]="null" disabled>Selecione o Gênero</option>
                <option value="MALE">Masculino</option>
                <option value="FEMALE">Feminino</option>
                <option value="OTHER">Outro</option>
              </select>
            </app-form>
        
            <app-form [label]="'Estado Civil'" [errorMessage]="'Estado Civil é obrigatório.'">
              <select formControlName="maritalStatus"
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;">
                <option [ngValue]="null" disabled>Selecione o Estado Civil</option>
                <option value="SINGLE">Solteiro(a)</option>
                <option value="MARRIED">Casado(a)</option>
                <option value="DIVORCED">Divorciado(a)</option>
                <option value="WIDOWED">Viúvo(a)</option>
              </select>
            </app-form>

              <app-form [label]="'CEP'" [errorMessage]="'CEP é obrigatório.'">
                <input formControlName="zipCodeAdmin" placeholder="CEP"
                  (input)="applyCepMaskForAdmin($event, i)"
                  style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;" />
              </app-form>

              <app-form [label]="'Endereço - Bairro'" [errorMessage]="'Bairro é obrigatória.'">
                <input formControlName="neighborhoodAdmin" placeholder="Bairro" 
                  style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;" />
              </app-form>

              <app-form [label]="'Endereço - Rua'" [errorMessage]="'Rua é obrigatória.'">
                <input formControlName="streetAdmin" placeholder="Rua" 
                  style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;" />
              </app-form>

              <app-form [label]="'Número'" [errorMessage]="'Número é obrigatório.'">
                <input formControlName="numberAdmin" placeholder="Número" 
                  style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;" />
              </app-form>
              
              <app-form [label]="'Cidade'" [errorMessage]="'Cidade é obrigatória.'">
                <input formControlName="cityAdmin" placeholder="Cidade" 
                  style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;" />
              </app-form>
              
              <app-form [label]="'UF'" [errorMessage]="'UF é obrigatória.'">
                <input formControlName="ufAdmin" placeholder="UF" 
                  style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px; margin-bottom: 8px;" />
              </app-form>
              
            <div style="display: flex; justify-content: space-between; margin-top: 16px;">
              <button (click)="removeAdmin(i)" 
                style="height: 50px; background-color: #d9534f; color: white; border: none; border-radius: 4px; padding: 0 16px;">
                Remover Administrador
              </button>
              
              <button type="button" (click)="addAdmin()" 
                style="height: 50px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; padding: 0 16px;">
                Adicionar Administrador
              </button>            
            </div>
          </div>
        </div>
    
        <div *ngIf="activeTab !== totalTabs"
            style="display: flex; align-items: center; justify-content: end; gap: 10px; margin-top: 20px;">
          <div *ngIf="activeTab > 0" (click)="setActiveTab(activeTab - 1)" [disabled]="loading"
              style="border: 1px solid #E5E5E5; cursor: pointer; width: 120px; background-color: transparent; color: #333333; border-radius: 8px; padding: 5px 0; display: flex; align-items: center; justify-content: center;">
            Voltar
          </div>
          <div (click)="setActiveTab(activeTab + 1)" [disabled]="loading"
              style="border: 1px solid #035A7A; cursor: pointer; width: 120px; background-color: transparent; color: #035A7A; border-radius: 8px; padding: 5px 0; display: flex; align-items: center; justify-content: center;">
            Próximo
          </div>
        </div>

        <div *ngIf="activeTab === totalTabs"
            style="display: flex; align-items: center; justify-content: end; gap: 10px; margin-top: 20px;">
          <button type="submit" [disabled]="loading" class="btn btn-upangel"
                  style="background: #035A7A; cursor: pointer; width: 120px;">
            Finalizar
          </button>
        </div>

      </div>
    </section>
  </div>

  <div 
  class="mobile-update"
  style="background: white; position: fixed; bottom: 0; width: 100%; height: 60px; align-items: center; justify-content: center; box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);">
    <div 
    style="width: 100%;"
    class="row">
      <div class="col-xs-12">
        <style>
          .btn-upangel:hover {
            color: #FFFFFF !important;
          }
        </style>
        <button 
        type="submit" 
        [disabled]="loading"
        class="btn btn-upangel"
        style="background: #035A7A; cursor: pointer; width: 100%;">
          Atualizar
        </button>
      </div>
    </div>
  </div>

  <div 
  *ngIf="!loader"
  class="content-mobile">
    <div style="display: flex; align-items: center; gap: 10px;">
      <a href="admin/rounds/assets/list">
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.375 6V14H14.625V6L9 2L3.375 6Z" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M7.12508 9.66641V13.9997H10.8751V9.66641H7.12508Z" stroke="#909090" stroke-width="1.5"
            stroke-linejoin="round" />
          <path d="M3.375 14H14.625" stroke="#909090" stroke-width="1.5" stroke-linecap="round" />
        </svg>
      </a>
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.95833 3.5L8.45833 7L4.95833 10.5" stroke="#909090" stroke-width="1.5" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
      <p style="color: #909090; margin-bottom: 0;">{{ titleHeader.title }}</p>
    </div>

    <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
      <div style="display: flex; align-items: center;">
        <img width="40px" height="40px" style="border-radius: 50%; border: 1px solid #E5E5E5;" src="{{ base64textString }}"
          title="Selecione uma imagem com altura e largura iguais." id="img" />
        <div style="margin-left: 16px;">
          <div>
            <h3 style="margin-left: 10px; font-size: 14px; color: #1F1F1F; font-family: Poppins, sans-serif;">
              <ng-container *ngIf="investor?.fullName">
                {{ investor.fullName }}
              </ng-container>
            </h3>
            <p style="color: #505050; font-family: Poppins, sans-serif;">@{{ investor?.nickname }}</p>
          </div>
        </div>
      </div>

      <div>
        <p style="gap: 16px; display: flex;">
          <span class="social {{ facebook == null ? 'isDisabled' : '' }}"><a href="{{ facebook == null ? '#' : facebook }}"
              target="_blank">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_358_1873)">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M1.37036 15.3327C0.981717 15.3327 0.666656 15.0159 0.666656 14.6251V1.3736C0.666656 0.982809 0.981717 0.666016 1.37036 0.666016H14.6296C15.0183 0.666016 15.3333 0.982809 15.3333 1.3736V14.6251C15.3333 15.0159 15.0183 15.3327 14.6296 15.3327C14.1605 15.3327 9.74072 15.3327 1.37036 15.3327Z"
                    stroke="#035A7A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.8872 5.96319H11.4359H11.2644C10.8144 5.96319 10.4496 6.32803 10.4496 6.77803V8.40813H12.8872L12.5302 10.8517H10.4496V15.3342H7.7689V10.8517H5.55566V8.40813H7.7403L7.7689 6.08866L7.76093 5.25736C7.74966 4.08346 8.69213 3.1227 9.86603 3.11142C9.87283 3.11136 9.87963 3.11133 9.88643 3.11133H12.8872V5.96319Z"
                    fill="#035A7A" />
                </g>
                <defs>
                  <clipPath id="clip0_358_1873">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg></a>
          </span>
          <span class="social {{ linkedin == null ? 'isDisabled' : '' }}"><a href="{{ linkedin == null ? '#' : linkedin }}"
              target="_blank">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_358_1876)">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.00001 14.6673C11.6819 14.6673 14.6667 11.6826 14.6667 8.00065C14.6667 4.31875 11.6819 1.33398 8.00001 1.33398C4.31811 1.33398 1.33334 4.31875 1.33334 8.00065C1.33334 11.6826 4.31811 14.6673 8.00001 14.6673Z"
                    stroke="#035A7A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M1.33334 8H14.6667" stroke="#035A7A" stroke-width="1.66667" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M8.00001 14.6673C9.47278 14.6673 10.6667 11.6826 10.6667 8.00065C10.6667 4.31875 9.47278 1.33398 8.00001 1.33398C6.52724 1.33398 5.33334 4.31875 5.33334 8.00065C5.33334 11.6826 6.52724 14.6673 8.00001 14.6673Z"
                    stroke="#035A7A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M3.28597 3.38086C4.4924 4.58729 6.15907 5.33349 8 5.33349C9.84097 5.33349 11.5076 4.58729 12.7141 3.38086"
                    stroke="#035A7A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M12.7141 12.6186C11.5076 11.4122 9.84098 10.666 8.00001 10.666C6.15908 10.666 4.49241 11.4122 3.28598 12.6186"
                    stroke="#035A7A" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_358_1876">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a></span>
          <span class="social {{ twitter == null ? 'isDisabled' : '' }}"><a href="{{ twitter == null ? '#' : twitter }}"
              target="_blank">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.66666 11.9214C2.30961 12.2768 6.96379 14.9597 10.2733 12.892C13.5828 10.8242 13.4002 7.26175 13.4002 5.62932C13.7 5.00125 14.3333 4.68195 14.3333 2.98192C13.7112 3.55658 13.0929 3.75212 12.4783 3.56852C11.8762 2.65051 11.0478 2.24447 9.99312 2.35043C8.41109 2.50936 7.83229 4.06148 8.00262 6.06955C5.56329 7.30312 3.65049 5.17532 2.66472 3.56852C2.33535 4.83395 2.01776 6.35318 2.66472 8.03382C3.09602 9.15422 4.13282 10.1014 5.77512 10.8755C4.11076 11.7776 2.74126 12.1262 1.66666 11.9214Z"
                  stroke="#035A7A" stroke-width="1.66667" stroke-linejoin="round" />
              </svg>
            </a></span>
        </p>
      </div>
    </div>

    <div class="grid-container">
      <p class="grid-item" *ngIf="investor?.publicProfile">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_358_2699)">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.00004 14.6673C11.6819 14.6673 14.6667 11.6826 14.6667 8.00065C14.6667 4.31875 11.6819 1.33398 8.00004 1.33398C4.31814 1.33398 1.33337 4.31875 1.33337 8.00065C1.33337 11.6826 4.31814 14.6673 8.00004 14.6673Z"
              stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.33337 8H14.6667" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.00004 14.6673C9.47281 14.6673 10.6667 11.6826 10.6667 8.00065C10.6667 4.31875 9.47281 1.33398 8.00004 1.33398C6.52727 1.33398 5.33337 4.31875 5.33337 8.00065C5.33337 11.6826 6.52727 14.6673 8.00004 14.6673Z"
              stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M3.28601 3.38086C4.49244 4.58729 6.15911 5.33349 8.00004 5.33349C9.84101 5.33349 11.5077 4.58729 12.7141 3.38086"
              stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M12.7141 12.6186C11.5077 11.4122 9.84101 10.666 8.00004 10.666C6.15911 10.666 4.49244 11.4122 3.28601 12.6186"
              stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_358_2699">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Perfil público
      </p>
      <p class="grid-item" *ngIf="!investor?.publicProfile">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_358_2699)">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.00004 14.6673C11.6819 14.6673 14.6667 11.6826 14.6667 8.00065C14.6667 4.31875 11.6819 1.33398 8.00004 1.33398C4.31814 1.33398 1.33337 4.31875 1.33337 8.00065C1.33337 11.6826 4.31814 14.6673 8.00004 14.6673Z"
              stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.33337 8H14.6667" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.00004 14.6673C9.47281 14.6673 10.6667 11.6826 10.6667 8.00065C10.6667 4.31875 9.47281 1.33398 8.00004 1.33398C6.52727 1.33398 5.33337 4.31875 5.33337 8.00065C5.33337 11.6826 6.52727 14.6673 8.00004 14.6673Z"
              stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M3.28601 3.38086C4.49244 4.58729 6.15911 5.33349 8.00004 5.33349C9.84101 5.33349 11.5077 4.58729 12.7141 3.38086"
              stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M12.7141 12.6186C11.5077 11.4122 9.84101 10.666 8.00004 10.666C6.15911 10.666 4.49244 11.4122 3.28601 12.6186"
              stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_358_2699">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        Perfil privado
      </p>
      <p class="grid-item">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_358_2731)">
            <path
              d="M8.00004 6.66732C9.47281 6.66732 10.6667 5.47342 10.6667 4.00065C10.6667 2.52789 9.47281 1.33398 8.00004 1.33398C6.52727 1.33398 5.33337 2.52789 5.33337 4.00065C5.33337 5.47342 6.52727 6.66732 8.00004 6.66732Z"
              stroke="#035A7A" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M8 6.66602V12.666" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M5.33337 10.666H4.00004L1.33337 14.666H14.6667L12 10.666H10.6667" stroke="#035A7A" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_358_2731">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {{
        descriptionCity == null
        ? "Sem informação"
        : descriptionCity
        }}
      </p>
      <p class="grid-item">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1.66663 6.33398H14.3333V13.334C14.3333 13.7022 14.0348 14.0007 13.6666 14.0007H2.33329C1.9651 14.0007 1.66663 13.7022 1.66663 13.334V6.33398Z"
            stroke="#035A7A" stroke-width="1.5" stroke-linejoin="round" />
          <path
            d="M1.66663 3.00065C1.66663 2.63246 1.9651 2.33398 2.33329 2.33398H13.6666C14.0348 2.33398 14.3333 2.63246 14.3333 3.00065V6.33398H1.66663V3.00065Z"
            stroke="#035A7A" stroke-width="1.5" stroke-linejoin="round" />
          <path d="M5.33337 1.33398V4.00065" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M10.6666 1.33398V4.00065" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M9.33337 11.334H11.3334" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M4.66663 11.334H6.66663" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M9.33337 8.66602H11.3334" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M4.66663 8.66602H6.66663" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        {{
        descriptionDate == null
        ? "Sem informação"
        : "Nasceu em " + descriptionDate
        }}
      </p>
      <p class="grid-item">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_358_2744)">
            <path
              d="M14.3643 3.99249L12.0072 1.63547C11.7469 1.37512 11.3248 1.37512 11.0644 1.63547L7.76459 4.9353C7.50424 5.19565 7.50424 5.61776 7.76459 5.87811L10.1216 8.23513C10.382 8.49548 10.8041 8.49548 11.0644 8.23513L14.3643 4.9353C14.6246 4.67495 14.6246 4.25284 14.3643 3.99249Z"
              stroke="#035A7A" stroke-width="1.5" stroke-linejoin="round" />
            <path
              d="M8.23602 10.1214L5.879 7.76437C5.61865 7.50402 5.19654 7.50402 4.93619 7.76437L1.63636 11.0642C1.37601 11.3246 1.37601 11.7467 1.63636 12.007L3.99338 14.364C4.25373 14.6244 4.67584 14.6244 4.93619 14.364L8.23602 11.0642C8.49637 10.8039 8.49637 10.3817 8.23602 10.1214Z"
              stroke="#035A7A" stroke-width="1.5" stroke-linejoin="round" />
            <path d="M10.3574 5.64258L5.64337 10.3566" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_358_2744">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        {{
        descriptionSite == null
        ? "Sem informação"
        : descriptionSite
        }}
      </p>
    </div>

    <div style="display: flex; align-items: center; gap: 15px; overflow-x: auto; width: 100%; ">
      <div *ngFor="let tab of tabs; let i = index" (click)="setActiveTab(i)"
        [style.color]="i === activeTab ? '#035A7A' : '#909090'"
        [style.border-color]="i === activeTab ? '#035A7A' : '#E8E5E5'"
        style="padding: 0px 16px; background: white; border-radius: 100px; border: 1px solid; margin-top: 15px; cursor: pointer;">
        <p style="margin-bottom: 0px; white-space: nowrap;">{{ tab }}</p>
      </div>
    </div>

    <div style="margin-top: 32px; border-bottom: 1px solid #E5E5E5;">
      <h3 style="font-weight: 600; margin-bottom: 12px;">{{ tabs[activeTab] }}</h3>
      <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">{{ tabContents[activeTab] }}</p>
    </div>

    <div 
    style="margin-bottom: 80px;"
    *ngIf="activeTab === 0">
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; width: 100%;">
        <div style="width: 50%;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">Profissão</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Minha profissão</p>
        </div>
    
        <div
        style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px; margin-bottom: 10px;">
        <input type="text" formControlName="profession"
          style="height: 100%; width: 100%; background-color: #FBFBFB; border: none; padding: 0 16px; outline: none;"
          placeholder="Minha profissão" />        
        </div>          
      </div>
    
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; width: 100%;">
        <div style="width: 50%; display: flex; flex-direction: column; align-items: start;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">Pessoal</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Informações pessoais</p>
        </div>
    
        <div>
          <app-form [label]="'Data de nascimento'" [errorMessage]="'Data de nascimento é um campo obrigatório.'">
            <input type="text" (input)="formatDate()" (blur)="validateDate()" formControlName="dateOfBirth"
              style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
              placeholder="Data de nascimento" />
          </app-form>
    
          <app-form [label]="'Nacionalidade'" [errorMessage]="'Nacionalidade é um campo obrigatório.'">
            <select formControlName="nationality"
              style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
              <option [ngValue]="null">Selecione uma opção...</option>
              <option value="BRASILEIRO">Brasileiro</option>
              <option value="AMERICANO">Americano</option>
              <option value="ANTIGUANO">Antiguano</option>
              <option value="ARGENTINO">Argentino</option>
              <option value="BAHAMENSE">Bahamense</option>
              <option value="BARBADIANO">Barbadiano</option>
              <option value="BELIZENHO">Belizenho</option>
              <option value="BOLIVIANO">Boliviano</option>
              <option value="CHILENO">Chileno</option>
              <option value="COLOMBIANO">Colombiano</option>
              <option value="COSTARRIQUENHO">Costarriquenho</option>
              <option value="CUBANO">Cubano</option>
              <option value="DOMINICANO">Dominicano</option>
              <option value="EQUATORIANO">Equatoriano</option>
              <option value="SALVADORENHO">Salvadorenho</option>
              <option value="GRANADINO">Granadino</option>
              <option value="GUATEMALTECO">Guatemalteco</option>
              <option value="GUIANÊS">Guianês</option>
              <option value="GUIANENSE">Guianense</option>
              <option value="HAITIANO">Haitiano</option>
              <option value="HONDURENHO">Hondurenho</option>
              <option value="JAMAICANO">Jamaicano</option>
              <option value="MEXICANO">Mexicano</option>
              <option value="NICARAGUENSE">Nicaraguense</option>
              <option value="PANAMENHO">Panamenho</option>
              <option value="PARAGUAIO">Paraguaio</option>
              <option value="PERUANO">Peruano</option>
              <option value="PORTORRIQUENHO">Portorriquenho</option>
              <option value="DOMINICANA">Dominicana</option>
              <option value="SÃO-CRISTOVENSE">São-cristovense</option>
              <option value="SÃO-VICENTINO">São-vicentino</option>
              <option value="SANTA-LUCENSE">Santa-lucense</option>
              <option value="SURINAMÊS">Surinamês</option>
              <option value="TRINDADENSE">Trindadense</option>
              <option value="URUGUAIO">Uruguaio</option>
              <option value="VENEZUELANO">Venezuelano</option>
              <option value="ALEMÃO">Alemão</option>
              <option value="AUSTRÍACO">Austríaco</option>
              <option value="BELGA">Belga</option>
              <option value="CROATA">Croata</option>
              <option value="DINAMARQUÊS">Dinamarquês</option>
              <option value="ESLOVACO">Eslovaco</option>
              <option value="ESLOVENO">Esloveno</option>
              <option value="ESPANHOL">Espanhol</option>
              <option value="FRANCÊS">Francês</option>
              <option value="GREGO">Grego</option>
              <option value="HÚNGARO">Húngaro</option>
              <option value="IRLANDÊS">Irlandês</option>
              <option value="ITALIANO">Italiano</option>
              <option value="NORUEGO">Noruego</option>
              <option value="HOLANDÊS">Holandês</option>
              <option value="POLONÊS">Polonês</option>
              <option value="PORTUGUÊS">Português</option>
              <option value="BRITÂNICO">Britânico</option>
              <option value="INGLÊS">Inglês</option>
              <option value="GALÊS">Galês</option>
              <option value="ESCOCÊS">Escocês</option>
              <option value="ROMENO">Romeno</option>
              <option value="RUSSO">Russo</option>
              <option value="SÉRVIO">Sérvio</option>
              <option value="SUECO">Sueco</option>
              <option value="SUÍÇO">Suíço</option>
              <option value="TURCO">Turco</option>
              <option value="UCRANIANO">Ucraniano</option>
              <option value="CANADENSE">Canadense</option>
              <option value="ANGOLANO">Angolano</option>
              <option value="MOÇAMBICANO">Moçambicano</option>
              <option value="SUL-AFRICANO">Sul-africano</option>
              <option value="ZIMBABUENSE">Zimbabuense</option>
              <option value="ARGÉLIA">Argélia</option>
              <option value="COMORENSE">Comorense</option>
              <option value="EGÍPCIO">Egípcio</option>
              <option value="LÍBIO">Líbio</option>
              <option value="MARROQUINO">Marroquino</option>
              <option value="GANÉS">Ganés</option>
              <option value="QUENIANO">Queniano</option>
              <option value="RUANDÊS">Ruandês</option>
              <option value="UGANDENSE">Ugandense</option>
              <option value="BECHUANO">Bechuano</option>
              <option value="MARFINENSE">Marfinense</option>
              <option value="CAMARONENSE">Camaronense</option>
              <option value="NIGERIANO">Nigeriano</option>
              <option value="SOMALI">Somali</option>
              <option value="AUSTRALIANO">Australiano</option>
              <option value="NEOZELANDÊS">Neozelandês</option>
              <option value="AFEGÃO">Afegão</option>
              <option value="SAUDITA">Saudita</option>
              <option value="ARMENO">Armeno</option>
              <option value="BANGLADESH">Bangladesh</option>
              <option value="CHINÊS">Chinês</option>
              <option value="NORTE-COREANO">Norte-coreano</option>
              <option value="SUL-COREANO">Sul-coreano</option>
              <option value="INDIANO">Indiano</option>
              <option value="INDONÉSIO">Indonésio</option>
              <option value="IRAQUIANO">Iraquiano</option>
              <option value="IRANIANO">Iraniano</option>
              <option value="ISRAELITA">Israelita</option>
              <option value="JAPONÊS">Japonês</option>
              <option value="MALAIO">Malaio</option>
              <option value="NEPALÊS">Nepalês</option>
              <option value="OMANENSE">Omanense</option>
              <option value="PAQUISTANÊS">Paquistanês</option>
              <option value="PALESTINO">Palestino</option>
              <option value="QATARENSE">Qatarense</option>
              <option value="SÍRIO">Sírio</option>
              <option value="CINGALÊS">Cingalês</option>
              <option value="TAILANDÊS">Tailandês</option>
              <option value="TIMORENSE">Timorense</option>
              <option value="ÁRABE">Árabe</option>
              <option value="VIETNAMITA">Vietnamita</option>
              <option value="IEMENITA">Iemenita</option>
            </select>
          </app-form>
    
          <app-form [label]="'Sexo'" [errorMessage]="'Sexo é um campo obrigatório.'">
            <select formControlName="gender"
              style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
              <option [ngValue]="null">Selecione uma opção...</option>
              <option value="MALE">Masculino</option>
              <option value="FEMALE">Feminino</option>
            </select>
          </app-form>
    
          <app-form [label]="'Estado civil'" [errorMessage]="'Estado civil é um campo obrigatório.'">
            <select formControlName="maritalStatus"
              style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
              <option [ngValue]="null">Selecione uma opção...</option>
              <option value="SINGLE">Solteiro</option>
              <option value="MARRIED">Casado</option>
              <option value="SEPARATED">Separado</option>
              <option value="DIVORCED">Divorciado</option>
              <option value="WIDOWED">Viúvo</option>
            </select>
          </app-form>
        </div>
      </div>
    
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; width: 100%;">
        <div style="width: 30%; display: flex; flex-direction: column; align-items: start;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">Identificação</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Detalhes de Identificação</p>
        </div>
    
        <div>
          <app-form [label]="investor?.cnpj !== undefined
                      ? 'Número do RG do sócio Representante'
                      : 'Número do RG'" [errorMessage]="'Número do RG é um campo obrigatório.'">
            <input type="text" formControlName="rg"
              style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
              maxlength="13" placeholder="Número do RG" />
          </app-form>
    
          <app-form [label]="'Orgão emissor'" [errorMessage]="'Orgão emissor é um campo obrigatório.'">
            <select formControlName="rgEmitter"
              style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
              <option [ngValue]="null">Selecione uma opção...</option>
              <option value="SSP">SSP</option>
              <option value="SSP-AC">SSP-AC</option>
              <option value="SSP-AL">SSP-AL</option>
              <option value="SSP-AM">SSP-AM</option>
              <option value="SSP-AP">SSP-AP</option>
              <option value="SSP-BA">SSP-BA</option>
              <option value="SSP-CE">SSP-CE</option>
              <option value="SSP-DF">SSP-DF</option>
              <option value="SSP-ES">SSP-ES</option>
              <option value="SSP-GO">SSP-GO</option>
              <option value="SSP-MA">SSP-MA</option>
              <option value="SSP-MG">SSP-MG</option>
              <option value="SSP-MS">SSP-MS</option>
              <option value="SSP-MT">SSP-MT</option>
              <option value="SSP-PA">SSP-PA</option>
              <option value="SSP-PB">SSP-PB</option>
              <option value="SSP-PE">SSP-PE</option>
              <option value="SSP-PI">SSP-PI</option>
              <option value="SSP-PR">SSP-PR</option>
              <option value="SSP-RJ">SSP-RJ</option>
              <option value="SSP-RN">SSP-RN</option>
              <option value="SSP-RO">SSP-RO</option>
              <option value="SSP-RR">SSP-RR</option>
              <option value="SSP-RS">SSP-RS</option>
              <option value="SSP-SC">SSP-SC</option>
              <option value="SSP-SE">SSP-SE</option>
              <option value="SSP-SP">SSP-SP</option>
              <option value="SSP-TO">SSP-TO</option>
              <option value="CREA">CREA</option>
              <option value="MAE">MAE</option>
              <option value="IML">IML</option>
              <option value="OAB">OAB</option>
              <option value="CARTORIO">Cartório Civil</option>
              <option value="DETRAN">DETRAN</option>
              <option value="FEDERAL">Polícia Federal</option>
            </select>
          </app-form>
    
          <div style="margin-bottom: 20px;">
            <p style="margin: 0; padding: 0; font-size: 14px; font-weight: 600;">Frente RG</p>
            <div
              style="height: 50px; width: 100%; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.16665 18.3327H15.8333C16.2936 18.3327 16.6666 17.9596 16.6666 17.4993V5.83268H12.5V1.66602H4.16665C3.70641 1.66602 3.33331 2.03911 3.33331 2.49935V17.4993C3.33331 17.9596 3.70641 18.3327 4.16665 18.3327Z"
                  stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.5 1.66602L16.6667 5.83268" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M7.49998 8.74935C8.42045 8.74935 9.16665 8.00316 9.16665 7.08268C9.16665 6.16221 8.42045 5.41602 7.49998 5.41602C6.57951 5.41602 5.83331 6.16221 5.83331 7.08268C5.83331 8.00316 6.57951 8.74935 7.49998 8.74935Z"
                  stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.25 11.6667V15.4167H13.75V8.75L9.78725 13.125L6.25 11.6667Z" stroke="#035A7A" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
    
              <button style="margin-left: 20px; margin-bottom: 0;" class="btn-editar-rg btn-photo" type="button">
                <label for="rgInputFile" style="font-size: 1.5rem">
                  {{base64RG ? 'Editar' : 'Anexar'}} RG &nbsp;
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </label>
                <input id="rgInputFile" style="display: none" title="Submeta a frente  do RG" type="file"
                  (change)="onUploadRG($event)" accept=".png, .jpg, .jpeg" />
              </button>

              <div *ngIf="fileNameRG && fileSizeRG" style="margin-left: 20px;">
                <p style="margin: 0;">{{ fileNameRG }} ({{ fileSizeRG }} MB)</p>
              </div>

            </div>
          </div>
    
          <div style="margin-bottom: 20px;">
            <p style="margin: 0; padding: 0; font-size: 14px; font-weight: 600;">Verso RG</p>
    
            <div
              style="height: 50px; width: 100%; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.16665 18.3327H15.8333C16.2936 18.3327 16.6666 17.9596 16.6666 17.4993V5.83268H12.5V1.66602H4.16665C3.70641 1.66602 3.33331 2.03911 3.33331 2.49935V17.4993C3.33331 17.9596 3.70641 18.3327 4.16665 18.3327Z"
                  stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.5 1.66602L16.6667 5.83268" stroke="#035A7A" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M7.49998 8.74935C8.42045 8.74935 9.16665 8.00316 9.16665 7.08268C9.16665 6.16221 8.42045 5.41602 7.49998 5.41602C6.57951 5.41602 5.83331 6.16221 5.83331 7.08268C5.83331 8.00316 6.57951 8.74935 7.49998 8.74935Z"
                  stroke="#035A7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6.25 11.6667V15.4167H13.75V8.75L9.78725 13.125L6.25 11.6667Z" stroke="#035A7A" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
    
              <button style="margin-left: 20px; margin-bottom: 0;" class="btn-editar-rg btn-photo" type="button">
                <label for="rgInputFileVerse" style="font-size: 1.5rem">
                  {{base64RG ? 'Editar' : 'Anexar'}} Verso RG &nbsp;
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </label>
                <input id="rgInputFileVerse" style="display: none" title="Submeta o verso do RG" type="file"
                  (change)="onUploadRGVerse($event)" accept=".png, .jpg, .jpeg" />
              </button>

              <div *ngIf="fileNameRGVerse && fileSizeRGVerse" style="margin-left: 20px;">
                <p style="margin: 0;">{{ fileNameRGVerse }} ({{ fileSizeRGVerse }} MB)</p>
              </div>

            </div>
          </div>
    
        </div>
      </div>
    
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; width: 100%;">
        <div style="width: 50%; display: flex; flex-direction: column; align-items: start;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">Contato</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Telefone do investidor</p>
        </div>
    
        <div>
          <app-form [label]="'Telefone'" [errorMessage]="'Telefone é um campo obrigatório.'">
            <input type="text" (input)="formatPhone()" formControlName="phone"
              style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
              placeholder="Telefone" />
          </app-form>
        </div>
      </div>

      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; display: flex; align-items: center; width: 100%;">
        <div style="width: 30%;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">Sócio Representante</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Informações do sócio Representante
          </p>
        </div>
      
        <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 16px;">
          <app-form [label]="'Nome do sócio Representante'"
            [errorMessage]="'Nome do sócio Representante é um campo obrigatório.'">
            <input type="text" formControlName="nameResponsible"
              style="height: 50px; width: 245px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
              placeholder="Nome do sócio Representante" />
          </app-form>
      
          <app-form [label]="'CPF do sócio Representante'"
            [errorMessage]="'CPF do sócio Representante é um campo obrigatório.'">
            <input type="text" formControlName="cpfResponsible"
              style="height: 50px; width: 245px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
              placeholder="CPF do sócio Representante" />
          </app-form>
        </div>
      </div>
    </div>

    <div 
    style="margin-bottom: 80px;"
    *ngIf="activeTab === 1">
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; width: 100%;">
        <div style="width: 50%;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">Detalhes</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Detalhes do endereço</p>
        </div>
    
        <app-form [label]="'CEP'" [errorMessage]="'CEP é um campo obrigatório.'">
          <input type="text" (input)="onCepInput()" formControlName="zipCode"
            style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
            placeholder="CEP" />
    
          <app-form [label]="'Logradouro'" [errorMessage]="'Logradouro é um campo obrigatório.'">
            <input type="text" formControlName="street"
              style="height: 50px; width: 100%; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
              placeholder="Logradouro" />
          </app-form>
    
          <div>
            <app-form [label]="'Número'" [errorMessage]="'Número é um campo obrigatório.'">
              <input type="text" formControlName="number"
                style="height: 50px; width: 100%; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                placeholder="Número" />
            </app-form>
    
            <app-form [label]="'Bairro'" [errorMessage]="'Bairro é um campo obrigatório.'">
              <input type="text" formControlName="neighborhood"
                style="height: 50px; width: 100%; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                placeholder="Bairro" />
            </app-form>
          </div>
    
          <app-form [label]="'Complemento'" [errorMessage]="'Complemento é um campo obrigatório.'">
            <input type="text" formControlName="complement"
              style="height: 50px; width: 100%; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
              placeholder="Complemento" />
          </app-form>
    
          <div>
            <app-form [label]="'Cidade'" [errorMessage]="'Cidade é um campo obrigatório.'">
              <input type="text" formControlName="city"
                style="height: 50px; width: 100%; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                placeholder="Cidade" />
            </app-form>
    
                  <app-form [label]="'Estado'" [errorMessage]="'Estado é um campo obrigatório.'">
                  <select 
                    formControlName="uf"
                    style="height: 50px; width: 100%; margin-top: 10px; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
                    <option [ngValue]="null">Selecione uma opção...</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </app-form>
          </div>
        </app-form>
      </div>
    </div>

    <div 
    style="margin-bottom: 80px;"
    *ngIf="activeTab === 2">
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; width: 100%;">
        <div style="width: 30%;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">Investimentos</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Conforme exigido pela Instrução
            CVM 588/2017.</p>
        </div>
    
        <app-radio [options]="investorProfileStatements" formControlName="investorProfileStatement">
        </app-radio>
      </div>
    
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; width: 100%;">
        <div style="width: 100%; justify-content: space-between; align-items: center; gap: 10px;">
          <h3 style="font-weight: 600; margin-bottom: 9px;">Declaração dos Valores Investidos no Ano-Calendário *
          </h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 12px; padding: 0;">
            Conforme exigido pela Instrução CVM 588/2017.
            Somente incluir o montante já investido no ano-
            calendário em ofertas dispensadas de registro nos
            termos da Instrução CVM 588/2017 por meio de
            plataformas eletrônicas de investimento participativo.
          </p>
        </div>
    
        <div style="width: 100%;">
          <app-form [label]="'Na plataforma da InvestPlus'" [errorMessage]="'Plataforma é um campo obrigatório.'">
            <div 
            style="width: 100%;"
            class="input-group">
              <input type="text" formControlName="investedUpangel"
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                disabled placeholder="Na plataforma da InvestPlus" />
            </div>
          </app-form>
    
          <app-form [label]="'Outras plataformas'" [errorMessage]="'Outras plataformas é um campo obrigatório.'">
            <div 
            style="width: 100%;"
            class="input-group">
              <input (keyup)="calculateInvestment(form.value.totalInvestedOthers)" type="text"
                formControlName="totalInvestedOthers"
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                maxlength="13" placeholder="Outras plataformas" />
            </div>
          </app-form>
    
          <app-form [label]="'Total'" [errorMessage]="'Total é um campo obrigatório.'">
            <div 
            style="width: 100%;"
            class="input-group">
              <input [(ngModel)]="investmentValue" type="text" formControlName="totalInvested"
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                disabled placeholder="Total" />
            </div>
          </app-form>
        </div>
      </div>
    
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; width: 100%; margin-bottom: 20px;">
        <div style="width: 100%;">
          <h3 style="font-weight: 600; margin-bottom: 9px;">Declaração pessoa politicamente exposta</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 12px; padding: 0;">
            Considera-se aquela que desempenha ou tenha
            desempenhado, nos últimos 5 (cinco) anos, cargos,
            empregos ou funções públicas relevantes, no Brasil ou em
            outros países, territórios e dependências estrangeiras,
            assim como seus representantes, familiares e outras
            pessoas de seu relacionamento próximo.
          </p>
        </div>
    
        <app-radio style="display: flex; gap: 16px;" [options]="publicFigures" formControlName="publicFigure">
    
        </app-radio>
      </div>
    </div>

    <div 
    style="margin-bottom: 80px;"
    *ngIf="activeTab === 3">
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; align-items: center; width: 100%;">
        <div style="width: 30%;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">Website</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Meu website</p>
        </div>
    
        <app-form [label]="'Website'" [errorMessage]="'Website é um campo obrigatório.'">
          <input type="text" formControlName="personalWebsite"
            style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; align-items: center; padding: 0 16px;"
            placeholder="Website" />
        </app-form>
      </div>
    
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; align-items: center; width: 100%;">
        <div style="width: 30%;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">facebook</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Meu facebook</p>
        </div>
    
        <app-form [label]="'Facebook'" [errorMessage]="'Facebook é um campo obrigatório.'">
          <input type="text" formControlName="facebook"
            style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; align-items: center; padding: 0 16px;"
            placeholder="Facebook" />
        </app-form>
      </div>
    
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; align-items: center; width: 100%;">
        <div style="width: 30%;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">Twitter</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Meu twitter</p>
        </div>
    
        <app-form [label]="'Twitter'" [errorMessage]="'Twitter é um campo obrigatório.'">
          <input type="text" formControlName="twitter"
            style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; align-items: center; padding: 0 16px;"
            placeholder="Twitter" />
        </app-form>
      </div>
    
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; align-items: center; width: 100%;">
        <div style="width: 30%;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">Linkedin</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Meu linkedin</p>
        </div>
    
        <app-form [label]="'Linkedin'" [errorMessage]="'Linkedin é um campo obrigatório.'">
          <input type="text" formControlName="linkedin"
            style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; align-items: center; padding: 0 16px;"
            placeholder="Linkedin" />
        </app-form>
      </div>
    
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; align-items: center; width: 100%;">
        <div style="width: 50%;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">InvestPlus</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Por onde conheceu a InvestPlus?
          </p>
        </div>
    
        <div>
          <app-form [label]="'Como você soube da InvestPlus'"
            [errorMessage]="'Como soube da InvestPlus é um campo obrigatório.'">
            <select formControlName="aboutUpangel"
              style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;">
              <option [ngValue]="null">Selecione uma opção...</option>
              <option value="INDICACAO">Uma indicação</option>
              <option value="EVENTO">Um evento</option>
              <option value="PESSOAL">Um encontro pessoal</option>
              <option value="IMPRENSA">Na imprensa</option>
              <option value="GOOGLE">Pela pesquisa Google</option>
              <option value="ONLINE">Por outra pesquisa online</option>
              <option value="FACEBOOK">Facebook</option>
              <option value="INSTAGRAM">Instagram</option>
              <option value="LINKEDIN">Linkedin</option>
              <option value="TWITTER">Twitter</option>
              <option value="PUBLICIDADE">Publicidade</option>
              <option value="OUTRO">Outro</option>
            </select>
          </app-form>
    
          <app-form [label]="'Código do agente indicador'" [errorMessage]="'Agente indicador é um campo obrigatório.'">
            <input type="text" formControlName="agent"
              style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
              maxlength="4" placeholder="Código do agente indicador" />
          </app-form>
        </div>
      </div>
    </div>
    
    <div 
    style="margin-bottom: 80px;"
    *ngIf="activeTab === 4">
      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5;align-items: center; width: 100%;">
        <div style="width: 30%;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">Conta</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Minha conta</p>
        </div>
    
        <div>
          <app-form [label]="'Instituição'" [errorMessage]="'Instituição é um campo obrigatório.'">
            <select formControlName="accountBank"
              style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
              placeholder="Instituição">
              <option [ngValue]="null">Selecione uma opção...</option>
              <ng-container *ngFor="let bank of banks">
                <option [value]="bank?.code">{{ bank?.name }}</option>
              </ng-container>
            </select>
          </app-form>
          <div>
            <app-form [label]="'Agência'" [errorMessage]="'Agência é um campo obrigatório.'">
              <input type="text" formControlName="accountAgency"
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                placeholder="Agência" />
            </app-form>
    
            <app-form [label]="'Conta Corrente (com dígito)'" [errorMessage]="'Conta é um campo obrigatório.'">
              <input type="text" formControlName="accountNumber"
                style="height: 50px; width: 100%; background-color: #FBFBFB; border: 1px solid #E5E5E5; border-radius: 4px; display: flex; align-items: center; padding: 0 16px;"
                placeholder="Conta Corrente (com dígito)" />
            </app-form>
          </div>
        </div>
    
    
      </div>

      <div style="margin-top: 24px; border-bottom: 1px solid #E5E5E5; align-items: center; width: 100%;">
        <div style="width: 30%;">
          <h3 style="font-weight: 600; margin-bottom: 12px;">Perfil público?</h3>
          <p style="margin-bottom: 12px; color: #505050; font-size: 14px; padding: 0;">Tornar o perfil básico público?
          </p>
        </div>
    
        <legend class="margin-t-20">
          <input type="checkbox" formControlName="publicProfile" class="flat-red" />
          Com um perfil público, outros usuários registrados na
          Plataforma InvestPlus conseguirão ver a sua foto.
        </legend>
      </div>
    </div>


   

  </div>
</form>